(function() {
  'use strict';

  angular
    .module('ng-mdsqre', [
      'ui.router',
      'ngCookies',
      'ngStorage',

      'ng-mdsqre.api',
      'ng-mdsqre.auth',
    ]);
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api', []);
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.auth', []);
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .config(bootstrap);

    /* @ngInject */
    function bootstrap($localStorageProvider) {
      $localStorageProvider.setKeyPrefix('mdsqresdk_');
    }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .factory('$mdsqre', mdsqreServiceProvider);

  /* @ngInject */
  function mdsqreServiceProvider(MdsqreApiRequester, MdsqreHelpers) {
    var service = {
      env: 'sandbox',
      requester: requester
    };
    return service;

    ////////////////

    function requester() {
      return new MdsqreApiRequester();
    }

    function env() {
      return this.env;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .constant('MDSQRE_URL', {
      local: 'http://127.0.0.1:8000',
      sandbox: 'https://sandbox.medsquire.com',
      production: 'https://api.medsquire.com',
    })
    .constant('MDSQRE_API_ENDPOINT', {
      local: 'http://127.0.0.1:8000/api/v1',
      sandbox: 'https://sandbox.medsquire.com/api/v1',
      production: 'https://api.medsquire.com/api/v1',
    });
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApi', MdsqreApi);

  /* @ngInject */
  function MdsqreApi($http, MDSQRE_URL, $log) {
    var MdsqreApi = {
      env: 'sandbox'
    };

    MdsqreApi.setEnv = setEnv;
    MdsqreApi.request = request;

    MdsqreApi.login = login;
    MdsqreApi.confirm = confirm;
    MdsqreApi.magicLink = magicLink;
    MdsqreApi.logout = logout;
    MdsqreApi.resetPassword = resetPassword;
    MdsqreApi.getMe = getMe;
    MdsqreApi.processUrl = processUrl;

    return MdsqreApi;

    ////////////////

    function setEnv(env) {
      this.env = env;
      return this;
    }

    function request(method, url, data) {
      $log.log(MDSQRE_URL);
      $log.log(MDSQRE_URL[this.env]);

      var request = {};
      request.method = method;
      request.url = this.processUrl(url);

      if (data) request['data'] = JSON.stringify(data);

      return $http(request);
    }

    function login(email, password) {
      return this.request('POST', 'api/auth/login', { email: email, password: password });
    }

    function confirm(credentials) {
      return this.request('POST', 'api/auth/login', credentials);
    }

    function magicLink(token, userId) {
      return this.request('GET', { url: 'api/auth/magiclink/' + token, params: { user_id: userId }});
    }

    function logout(data) {
      return this.request('POST', 'api/auth/logout', data);
    }

    function resetPassword(reset) {
      return this.request('POST', 'api/auth/reset/request', {
        email: reset.email,
        new_password: reset.new_password,
        redirect: reset.redirect
      });
    }

    function getMe() {
      return this.request('POST', 'api/auth/me');
    }

    function processUrl(url) {
      if (typeof url === 'string') {
        return MDSQRE_URL[this.env] + '/' + url;
      }
      console.log(url);
      var urlBase = url.url;
      var paramsObject = url.params;
      var params = '';
      for (var param in paramsObject) {
        if (paramsObject[param] instanceof Array) {
          paramsObject[param].forEach(function(_param) {
            params += param + '[]=' + _param + '&';
          });
        } else {
          params += param + '=' + paramsObject[param] + '&';
        }
      }
      return MDSQRE_URL[this.env] + '/' + urlBase + '?' + params + '&';
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('mdsqreRequestInterceptor', mdsqreRequestInterceptor)
    .config(setMdsqreInterceptors);

  /* @ngInject */
  function mdsqreRequestInterceptor($cookies, $log, $window, $q) {
    var interceptor = {
      request: request,
      response: response,
      requestError: requestError,
      responseError: responseError,
    };
    return interceptor;

    ////////////////

    function request(config) {
      if (config.url.indexOf('http') > -1) {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
        config.headers['X-MDSQRE-SDK'] = 'Angularjs v1.0.0';
        if ($window._Medsquire && $window._Medsquire.key) {
          config.headers['X-MDSQRE-KEY'] = $window._Medsquire.key;
        }
        config.headers['Authorization'] = 'Bearer ' + $cookies.get('auth_token');
        $log.log(config);
      }
      return config;
    }

    function response(response) {
      // do something
      return response;
    }

    function requestError(request) {
      $log.log('request error: ', request);
      return $q.reject(request);
    }

    function responseError(response) {
      $log.log('response error: ', response);
      if (response.status == 401 && $cookies.get('auth_token')) {
        $cookies.remove('auth_token');
        $window.location.reload();
      }
      return $q.reject(response);
    }
  }

  /* @ngInject */
  function setMdsqreInterceptors($httpProvider) {
    $httpProvider.interceptors.push('mdsqreRequestInterceptor');
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.auth')
    .constant('AUTH_EVENTS', {
      AUTHENTICATED: 'authenticated',
      UNAUTHENTICATED: 'unauthenticated'
    });
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.auth')
    .factory('MdsqreAuth', MdsqreAuth);

  /* @ngInject */
  function MdsqreAuth($rootScope, MdsqreApi, UserModel, $q, $cookies, $localStorage, $log, AUTH_EVENTS) {
    var MdsqreAuth = {
      user: null,
      organization: null,
    };

    MdsqreAuth.login = login;
    MdsqreAuth.confirm = confirm;
    MdsqreAuth.loginWithMagicLink = loginWithMagicLink;
    MdsqreAuth.logout = logout;
    MdsqreAuth.resetPassword = resetPassword;
    MdsqreAuth.getUser = getUser;
    MdsqreAuth.getOrganization = getOrganization;
    MdsqreAuth.isAuthenticated = isAuthenticated;
    MdsqreAuth.clearAuthSession = clearAuthSession;

    return MdsqreAuth;

    ////////////////

    function login(email, password) {
      var deferred = $q.defer();
      var self = this;
      MdsqreApi.login(email, password)
        .then(function(response) {
          $rootScope.$broadcast(AUTH_EVENTS.AUTHENTICATED, response.data);
          $log.log(response);
          $cookies.put('session_id', response.data.session_id);
          $cookies.put('auth_token', response.data.access_token);
          return response;
        })
        .then(function(response) {
          return getMe();
        })
        .then(function(user) {
          deferred.resolve(user);
        })
        .catch(function(error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function confirm(credentials) {
      var deferred = $q.defer();
      var self = this;
      MdsqreApi.confirm(credentials)
        .then(function(response) {
          $rootScope.$broadcast(AUTH_EVENTS.AUTHENTICATED, response.data);
          $log.log(response);
          $cookies.put('session_id', response.data.session_id);
          $cookies.put('auth_token', response.data.access_token);
          return response;
        })
        .then(function(response) {
          return getMe();
        })
        .then(function(user) {
          deferred.resolve(user);
        })
        .catch(function(error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function loginWithMagicLink(token, userId) {
      var deferred = $q.defer();
      var self = this;
      // should call logout routine to invalidate jwt.
      // just clearing the session doesnt invalidate the
      // token, and this could lead to some exploit to be
      // exploited by using a token that is not invalidated
      this.clearAuthSession();
      MdsqreApi.magicLink(token, userId)
        .then(function(response) {
          $rootScope.$broadcast(AUTH_EVENTS.AUTHENTICATED, response.data);
          $log.log(response);
          $cookies.put('session_id', response.data.session_id);
          $cookies.put('auth_token', response.data.access_token);
          return response;
        })
        .then(function(response) {
          return getMe();
        })
        .then(function(user) {
          deferred.resolve(user);
        })
        .catch(function(error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function logout() {
      var deferred = $q.defer();
      var self = this;
      MdsqreApi.logout({ session_id: $cookies.get('session_id') })
        .then(function(logoutResponse) {
          self.clearAuthSession();
          deferred.resolve(logoutResponse);
        })
        .catch(function(error) {
          $cookies.remove('session_id');
          $cookies.remove('auth_token');
          deferred.reject(error);
        })
        .finally(function() {
          $log.log('loggin out');
          $rootScope.$broadcast(AUTH_EVENTS.UNAUTHENTICATED, logoutResponse.data);
          $cookies.remove('auth_token');
          $log.log($cookies.getAll());
        });
      return deferred.promise;
    }

    function resetPassword(data) {
      var deferred = $q.defer();
      MdsqreApi.resetPassword(data)
        .then(function(resetRequestResponse) {
          deferred.resolve(resetRequestResponse.data);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getUser() {
      if (this.user && (this.user instanceof UserModel)) return this.user;
      if ($localStorage.user) {
        this.user = new UserModel($localStorage.user);
        return this.user;
      }
      return null;
    }

    function getOrganization() {
      if (this.organization) return this.organization;
      if ($localStorage.organization) {
        this.organization = $localStorage.organization;
        return this.organization;
      }
      return null;
    }

    function getMe() {
      var deferred = $q.defer();
      MdsqreApi.getMe()
        .then(function(userResponse) {
          var user = userResponse.data;
          $localStorage.user = new UserModel(user);
          if (user.organizations && user.organizations.length > 0) {
            $localStorage.organization = user.organizations[0];
          }
          deferred.resolve(userResponse.data);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function isAuthenticated() {
      var token = $cookies.get('auth_token');
      if (token) { return token };
      return false;
    }

    function clearAuthSession() {
      $cookies.remove('auth_token');
      $cookies.remove('session_id');
      $localStorage.user = null;
      $localStorage.organization = null;
      this.user = null;
      this.organization = null;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .constant('EVENT_STATES', {
      created: 'CRIADO',
      in_progress: 'ACONTECENDO',
      finished: 'ENCERRADO',
      invalid: 'INVÁLIDO',
    })
    .constant('SHIFT_EVENT_STATES', {
      created: 'CRIADO',
      assigned: 'AGENDADO',
      contested: 'CONTESTADO',
      validated: 'VALIDADO',
      canceled: 'CANCELADO',
    })
    .constant('APP_CONTEXTS', {
      available: ['professional', 'interns', 'university', 'shiftshare'],
      useStateGates: {
        professional: false,
        interns: true,
        university: true,
        shiftshare: false,
      },
      short: {
        professional: 'pro',
        interns: 'int',
        university: 'uni',
        shiftshare: 'ss',
      },
      long: {
        professional: 'professional',
        interns: 'interns',
        university: 'university',
      },
      attendant_label: {
        professional: 'médico',
        interns: 'residente',
        university: 'aluno'
      },
      monitor_label: {
        professional: 'monitor',
        interns: 'preceptor',
        university: 'residente ou preceptor',
      },
      auditor_label: {
        professional: 'audior',
        interns: 'coordenador',
        university: 'coordenador',
      },
      evaluator_label: {
        professional: 'audior',
        interns: 'preceptor',
        university: 'residente ou preceptor',
      }
    });
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .factory('MdsqreHelpers', MdsqreHelpers);

  /* @ngInject */
  function MdsqreHelpers($window, APP_CONTEXTS) {
    var service = {
      getJsonDiff: getJsonDiff,
      parseDayPeriod: parseDayPeriod,
      parseGoogleAddressComponents: parseGoogleAddressComponents,
      getContext: getContext,
      getLabel: getLabel,
    };
    return service;

    ////////////////

    function getJsonDiff(original, edited) {
      var ret = {};
      for (var i in edited) {
        if (!original.hasOwnProperty(i) || edited[i] !== original[i]) {
          ret[i] = edited[i];
        }
      }
      return ret;
    }

    function parseDayPeriod(startTime, endTime) {
      if (!startTime || !endTime) throw new Error('Must inform start and end arguments');
      var startTime = moment(startTime);
      var endTime = moment(endTime);
      if (!startTime.isValid() || !endTime.isValid()) throw new Error('Start and end must be valid dates');
      var duration = Math.abs(endTime.diff(startTime, 'hours'));

      var period = null;

      if (duration <= 1) {
        var hour = startTime.hour();
        if (hour > 19 || hour < 7) period = 'night';
        if (hour <= 19 && hour >= 7) period = 'day';
      }

      var dayTime = 0;
      var nightTime = 0;
      for (var i = 1; i < duration; i++) {
        var hour = startTime.add(1, 'h').hour();
        if (hour > 19 || hour <= 7) {
          nightTime++;
          period = 'night';
        }
        if (hour <= 19 && hour > 7) {
          dayTime++;
          period = 'day';
        }
      }
      if (dayTime > nightTime) period = 'day';
      if (dayTime < nightTime) period = 'night';

      return period;
    }

    function parseGoogleAddressComponents(addressArray, remap) {
      if (!addressArray || addressArray.length <= 0) {
        throw new Error('Must inform valid google address components data');
      }
      var address = {};
      addressArray.forEach(function(component) {
        if (component.types.indexOf('route') > -1) {
          address['street'] = component.short_name;
        }
        if (component.types.indexOf('street_number') > -1) {
          address['number'] = component.long_name;
        }
        if (component.types.indexOf('sublocality_level_1') > -1) {
          address['district'] = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_2') > -1) {
          address['city'] = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_1') > -1) {
          address['state'] = component.short_name;
        }
        if (component.types.indexOf('country') > -1) {
          address['country'] = component.short_name;
        }
        if (component.types.indexOf('postal_code') > -1) {
          address['zip'] = component.short_name;
          address['zip'] = address['zip'].replace('-', '');
        }
      });

      return address;
    }

    function getContext() {
      var context = $window._Medsquire.context;
      if (APP_CONTEXTS.available.indexOf(context > -1)) {
        return ({
          short: APP_CONTEXTS.short[context],
          long: APP_CONTEXTS.long[context],
          attendant_label: APP_CONTEXTS.attendant_label[context],
          monitor_label: APP_CONTEXTS.monitor_label[context],
          auditor_label: APP_CONTEXTS.auditor_label[context],
          evaluator_label: APP_CONTEXTS.evaluator_label[context]
        });
      }
    }

    function getLabel(label, capitalize) {
      if (capitalize) {
        var label = APP_CONTEXTS[label + '_label'][getContext().long];
        return label.charAt(0).toUpperCase() + label.slice(1)
      }
      return APP_CONTEXTS[label + '_label'][getContext().long];
    }

  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .filter('eventStateFilter', eventStateFilter)
    .filter('shiftStateFilter', shiftStateFilter);

  function eventStateFilter(EVENT_STATES) {
    return eventStateFilterFilter;

    ////////////////

    function eventStateFilterFilter(state) {
      return EVENT_STATES[state];
    }
  }

  function shiftStateFilter(SHIFT_EVENT_STATES) {
    return shiftStateFilterFilter;

    ////////////////

    function shiftStateFilterFilter(state) {
      return SHIFT_EVENT_STATES[state];
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .factory('MdsqreRemapper', MdsqreRemapper);

  /* @ngInject */
  function MdsqreRemapper(EventModel, ShiftModel, UserModel, RatingModel, NotificationModel, UnitModel,
    AddressModel, OfferModel, TransactionModel, Offerv2Model, ContractModel, CompanyModel, ContestationModel,
    CommentModel, ContactModel, ErrorModel) {
    var service = {
      user: user,
      event: event,
      offer: offer,
      offerv2: offerv2,
      transaction: transaction,
      shiftEvent: shiftEvent,
      rating: rating,
      notification: notification,
      unit: unit,
      contract: contract,
      company: company,
      address: address,
      contestation: contestation,
      comment: comment,
      contact: contact,
      error: error,
    };
    return service;

    ////////////////

    function user(user) {
      var user = new UserModel(user);
      user.getAvatarUrl();
      if (user.address) {
        user.address = this.address(user.address);
      }
      if (user.contact) {
        user.contact = this.contact(user.contact);
      }

      return user;
    }

    function event(event) {
      var self = this;
      var event = new EventModel(event);
      if (event.eventable_type === 'shift_event' && event.eventable) {
        event.eventable = new ShiftModel(event.eventable);
      }
      if (event.offers) {
        var offers = [];
        event.offers.forEach(function(offer) {
          offers.push(self.offer(offer));
        });
        event.offers = offers;
      }
      if (event.offersv2) {
        var offersv2 = [];
        event.offersv2.forEach(function(offerv2) {
          offersv2.push(self.offerv2(offerv2));
        });
        event.offersv2 = offersv2;
      }
      if (event.contestations) {
        var contestations = [];
        event.contestations.forEach(function(contestation) {
          contestations.push(self.contestation(contestation));
        });
        event.contestations = contestations;
      }
      if (event.subjects) {
        var subjects = [];
        event.subjects.forEach(function(subject) {
          subjects.push(self.user(subject));
        });
        event.subjects = subjects;
      }
      if (event.attendants) {
        var attendants = [];
        event.attendants.forEach(function(attendant) {
          attendants.push(self.user(attendant));
        });
        event.attendants = attendants;
      }
      return event;
    }

    function offer(offer) {
      var offer = new OfferModel(offer);
      if (offer.creator) {
        offer.creator = this.user(offer.creator);
      }
      if (offer.offerable && offer.offerable_type == 'App\\Event') {
        offer.event = this.event(offer.offerable);
      }
      if (offer.transaction) {
        offer.transaction = this.transaction(offer.transaction);
      }
      if (offer.transactions && offer.transactions.length > 0) {
        var transactions = [];
        var self = this;
        offer.transactions.forEach(function(transaction) {
          transactions.push(self.transaction(transaction));
        });
        offer.transactions = transactions;
      }
      return offer;
    }

    function transaction(transaction) {
      var transaction = new TransactionModel(transaction);
      if (transaction.transactionable &&
        transaction.transactionable_type === 'App\\OfferTransactionExchange') {
        transaction.from_event = this.event(transaction.transactionable.from_offerable);
        transaction.to_event = this.event(transaction.transactionable.to_offerable);
      }
      if (transaction.from_attendant && transaction.from_attendant.id) {
        transaction.from_attendant_id = transaction.from_attendant.id;
        transaction.from_attendant = this.user(transaction.from_attendant);
      }
      if (transaction.to_attendant && transaction.to_attendant.id) {
        transaction.to_attendant_id = transaction.to_attendant.id;
        transaction.to_attendant = this.user(transaction.to_attendant);
      }

      if (transaction.transactionable_type === 'App\\OfferTransactionExchange') {
        transaction.transactionable_type = 'exchange';
      } else if (transaction.transactionable_type === 'App\\OfferTransactionTransfer') {
        transaction.transactionable_type = 'transfer';
      }
      transaction.summary = transaction.getSummary();

      return transaction;
    }

    function offerv2(offer) {
      var self = this;
      var offer = new Offerv2Model(offer);
      if (offer.subjects) {
        var subjects = [];
        offer.subjects.forEach(function(subject) {
          var user = self.user(subject);
          user.proposals = subject.proposals;
          subjects.push(user);
        });
        offer.subjects = subjects;
      }
      if (offer.proposals) {
        var proposals = [];
        offer.proposals.forEach(function(proposal) {
          if (proposal.proposable && proposal.proposable_type == 'App\\Event') {
            proposal.proposable = self.event(proposal.proposable);
          }
          proposals.push(proposal);
        });
        offer.proposals = proposals;
      }

      return offer;
    }

    function shiftEvent(shift) {
      var shift = new ShiftModel(shift);
      if (shift.assigned_user) {
        shift.assigned_user = new UserModel(shift.assigned_user);
      }
      if (shift.event) {
        shift.event = new EventModel(shift.event);
      }
      return shift;
    }

    function rating(rating) {
      return new RatingModel(rating);
    }

    function notification(notification) {
      return new NotificationModel(notification);
    }

    function unit(unit) {
      var unit = new UnitModel(unit);
      if (unit.address) {
        unit.address = this.address(unit.address);
      }
      return unit;
    }

    function contract(contract) {
      var contract = new ContractModel(contract);
      if (contract.subjects) {
        var self = this;
        var subjects = [];
        contract.subjects.forEach(function(subject) {
          if (subject.subjectable_type === 'App\\Company') {
            var subjectable = subject.subjectable;
            if (subjectable) subjectable = self.company(subjectable);
            subject.subjectable = subjectable;
          }
          subjects.push(subject);
        });
        contract.subjects = subjects;
      }
      if (contract.child_contracts) {
        var self = this;
        var childContracts = [];
        contract.child_contracts.forEach(function(contract) {
          childContracts.push(self.contract(contract));
        });
        contract.child_contracts = childContracts;
      }

      return contract;
    }

    function company(company) {
      var company = new CompanyModel(company);
      if (company.address) {
        company.address = this.address(company.address);
      }

      return company;
    }

    function address(address) {
      var address = new AddressModel(address);
      return address;
    }

    function contestation(contestation) {
      var self = this;
      var contestation = new ContestationModel(contestation);
      if (contestation.comments) {
        var comments = [];
        contestation.comments.forEach(function(comment) {
          comments.push(self.comment(comment));
        });
        contestation.comments = comments;
      }
      return contestation;
    }

    function comment(comment) {
      var comment = new CommentModel(comment);
      if (comment.user) {
        comment.user = this.user(comment.user);
      }
      return comment;
    }

    function contact(contact) {
      var contact = new ContactModel(contact);
      return contact;
    }

    function error(error) {
      return new ErrorModel(error);
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre')
    .factory('MdsqreStateHandler', MdsqreStateHandler);

  /* @ngInject */
  function MdsqreStateHandler($rootScope, $state, MdsqreAuth, $location, $sessionStorage, $timeout, $log,
    $window, MdsqreHelpers, APP_CONTEXTS) {
    var service = {
      magicLoginRoute: 'magiclink.login',
      loginRoute: 'login',
      homeRoute: '*.home',

      watch: watch,
      sendAnalytics: sendAnalytics,
      handleTamperedUrl: handleTamperedUrl,
      handleMagicLogin: handleMagicLogin,
      handleStateGates: handleStateGates,
      handleRedirects: handleRedirects,
    };
    return service;

    ////////////////

    function watch() {

      var urlParams = $location.search();
      $sessionStorage.url_params = urlParams;
      $sessionStorage.redirect = {
        route: urlParams.route,
        param: urlParams.param,
        value: urlParams.value,
      };

      this.handleMagicLogin();

      var self = this;
      var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // self.handleTamperedUrl(event, fromState, toState);
        if (APP_CONTEXTS.useStateGates[MdsqreHelpers.getContext().long]) {
          self.handleStateGates(event, fromState, toState);
        }
        self.handleRedirects();
      });

      var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        self.sendAnalytics();
      });

      var stateError = $rootScope.$on('$stateChangeError', function (event, current, previous, rejection) {
        $log.error('Route change error.');
        $log.log(event);
        $log.log(current);
        $log.log(previous);
        $log.log(rejection);
        alert('Route change error.');
      });
    }

    function sendAnalytics() {
      if ($window.gtag) {
        $window.gtag('config', 'UA-99595693-2', { 'page_path': $location.path() });
      }
    }

    function handleTamperedUrl(event, fromState, toState) {
      var url = $location.path();
      $log.log(toState);
      if (toState.url && toState.url.indexOf(':') > -1 && !$window._Medsquire.stateTimestamp) {
        $state.go(this.homeRoute);
        event.preventDefault();
      }
      $window._Medsquire.stateTimestamp = null;
    }

    function handleMagicLogin() {
      if ($sessionStorage.url_params.auth_token && $sessionStorage.url_params.user_id) {
        $state.go(this.magicLoginRoute);
      }
    }

    function handleStateGates(event, fromState, toState) {
      var statePieces = toState.name.split('.');
      var stateLocked = false;

      if (statePieces.length > 0) {
        stateLocked = Object.values(APP_CONTEXTS.short).indexOf(statePieces[0]) > -1;
      }

      $log.log('=~= route manager =~=');
      $log.log(statePieces);
      $log.log('is locked: ', stateLocked);
      $log.log('=~= route manager =~=');

      if (stateLocked && !MdsqreAuth.isAuthenticated()) {
        $log.warn('Unauthenticated.');
        $state.go(this.loginRoute);
        event.preventDefault();
      }

      if (toState.name.indexOf(this.loginRoute) > -1 && MdsqreAuth.isAuthenticated()) {
        $log.warn('Authenticated, cannot see login.');
        $state.go(this.homeRoute);
        event.preventDefault();
      }
    }

    function handleRedirects() {
      if (MdsqreAuth.isAuthenticated() && $sessionStorage.redirect.route) {
        $log.warn('redirecting...');
        $timeout(function () {
          var params = {};
          if ($sessionStorage.redirect.param) {
            params[$sessionStorage.redirect.param] = $sessionStorage.redirect.value;
          }
          if ($sessionStorage.redirect.route) {
            $state.go($sessionStorage.redirect.route, params);
          }
          $sessionStorage.redirect = {};
        }, 200);
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('ErrorModel', ErrorModel);

  /* @ngInject */
  function ErrorModel(Model) {

    ////////////////

    /**
     * @constructor ErrorModel
     * @param {object}
     */
    function ErrorModel(data) {
      this._class = 'ErrorModel';
      this.message = data.message;
      this.errors = this.parseErrors(data.errors);
    }

    ErrorModel.prototype.parseErrors = function(errors) {
      var cleanErrors = [];
      if (errors && typeof errors === 'object') {
        for (var i in errors) {
          cleanErrors.push(errors[i][0]);
        }
      }
      return cleanErrors;
    };

    ErrorModel.prototype.print = function() {
      // var message = "\n" + this.message;
      var message = "\n";
      if (this.message) message += this.message + ": \n";
      if (this.errors.length > 0) {
        message += ": \n";
        this.errors.forEach(function(error) {
          message += error + " \n";
        });
      }
      return message;
    };

    return ErrorModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .factory('Model', Model);

  /* @ngInject */
  function Model() {

    /**
     * Base Model class
     * @param {object} data - Init model data
     */
    function Model(data) {
      if (typeof data !== 'object') {
        throw new Error('Must init model with valid data');
      }
      this._class = 'Model';
      this._sync = false;
      this.id = data.id;
      this.dateFormat = 'DD/MM/YYYY HH:mm';
    }

    /**
     * Return simple model object
     * @param  {bool} stringify - output string
     * @return {string/object}
     */
    Model.prototype.toObject = function(stringify) {
      if (!stringify) stringify = false;
      var object = {};
      for (var property in this) {
        if (typeof this[property] === 'function') continue;
        if (property.charAt(0) === '_') continue;
        if (typeof this[property] === 'object' && this[property] instanceof Model) {
          this[property] = this[property].toObject();
        }
        object[property] = this[property];
      }
      if (stringify) object = JSON.stringify(object);
      return object;
    };

    return Model;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiRequester', MdsqreApiRequester);

  /* @ngInject */
  function MdsqreApiRequester(BaseRequester, MdsqreRemapper, MDSQRE_URL, MdsqreAuth, $q, $log) {

    ////////////////

    /**
     * @constructor MdsqreApiRequester
     * @param {object}
     */
    function MdsqreApiRequester() {
      BaseRequester.call(this);
      this._class = 'MdsqreApiRequester';
    }

    MdsqreApiRequester.prototype = Object.create(BaseRequester.prototype);

    MdsqreApiRequester.prototype.authentication = function(custom) {
      this.baseUrl = MDSQRE_URL[this.env];
      if (custom && custom.type == 'organization') {
        this.routes.push('api/auth/login?organization=true');
      } else {
        this.routes.push('api/auth/login');
      }
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.auth = function() {
      this.baseUrl = MDSQRE_URL[this.env];
      this.routes.push('api/auth');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.checkAuth = function(custom) {
      this.baseUrl = MDSQRE_URL[this.env];
      this.routes.push('api/auth/check');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.resetAuth = function() {
      this.baseUrl = MDSQRE_URL[this.env];
      this.routes.push('api/auth/reset/request');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.register = function() {
      this.routes.push('register');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.organizations = function(id) {
      this.routes.push('organizations');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.events = function(id) {
      this.routes.push('events');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.event).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.presences = function(id) {
      this.routes.push('presences');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.contestations = function(id) {
      this.routes.push('contestations');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.contestation).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.comments = function(id) {
      this.routes.push('comments');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.comment).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.proposals = function(id) {
      this.routes.push('proposals');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.agree = function(id) {
      this.routes.push('agree');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.resolve = function(id) {
      this.routes.push('resolve');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.users = function(id) {
      this.routes.push('users');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.user).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.subjects = function(id) {
      this.routes.push('subjects');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.ratings = function(id) {
      this.routes.push('ratings');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.contracts = function(id) {
      this.routes.push('contracts');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.contract).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.items = function(id) {
      this.routes.push('items');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.settings = function(id) {
      this.routes.push('settings');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.roles = function(id) {
      this.routes.push('roles');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.permissions = function(id) {
      this.routes.push('permissions');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.services = function(id) {
      this.routes.push('services');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.invoices = function(id) {
      this.routes.push('invoices');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.transactions = function(id) {
      this.routes.push('transactions');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.units = function(id) {
      this.routes.push('units');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.unit).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.companies = function(id) {
      this.routes.push('companies');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.company).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.notifications = function(id) {
      this.routes.push('notifications');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.templates = function(id) {
      this.routes.push('templates');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.summary = function(id) {
      this.routes.push('summary');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.specialties = function(id) {
      this.routes.push('specialties');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.offers = function(id) {
      this.routes.push('offers');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.offersv2 = function(id) {
      this.routes.push('offersv2');
      if (id) this.routes.push(id);
      this.remapper = (MdsqreRemapper.offerv2).bind(MdsqreRemapper);
      return this;
    };

    MdsqreApiRequester.prototype.sessions = function(id) {
      this.routes.push('sessions');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.toggle = function() {
      this.routes.push('toggle');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.bulk = function() {
      this.routes.push('bulk');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.reports = function(id) {
      this.routes.push('reports');
      if (id) this.routes.push(id);
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.accept = function() {
      this.routes.push('accept');
      this.remapper = null;
      return this;
    };

    MdsqreApiRequester.prototype.verifications = function() {
      this.routes.push('verifications');
      this.remapper = null;
      return this;
    };

    return MdsqreApiRequester;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('AddressModel', AddressModel);

  /* @ngInject */
  function AddressModel(Model, MdsqreAuth) {

    ////////////////

    /**
     * @constructor AddressModel
     * @param {object}
     */
    function AddressModel(data) {
      Model.call(this, data);
      this._class = 'AddressModel';
      this.name = data.name;
      this.street = data.street;
      this.number = data.number;
      this.district = data.district;
      this.complement = data.complement;
      this.city = data.city;
      this.zip = data.zip;
      this.state = data.state;
      this.country = data.country;
      this.gplace_id = data.gplace_id;
    }

    AddressModel.prototype = Object.create(Model.prototype);

    AddressModel.prototype.getFullAddress = function() {
      var address = '';
      address += this.street + ', ' + this.number + ' - ';
      address += this.district + ', ' + this.city + ' - ';
      address += this.state + ', ' + this.zip + ', ' + this.country;
      return address;
    };

    return AddressModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('CommentModel', CommentModel);

  /* @ngInject */
  function CommentModel(Model, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor CommentModel
     * @param {object}
     */
    function CommentModel(data) {
      Model.call(this, data);
      this._class = 'CommentModel';
      this.user_id = data.user_id;
      this.user = data.user;
      this.commentable_type = data.commentable_type;
      this.commentable_id = data.commentable_id;
      this.title = data.title;
      this.body = data.body;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
      this.created_by = data.created_by;
      this.updated_by = data.updated_by;
      this.additional_info = data.additional_info;
    }

    CommentModel.prototype = Object.create(Model.prototype);

    CommentModel.prototype.fromNow = function() {
      return moment(this.created_at).fromNow();
    };

    return CommentModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiCompanies', MdsqreApiCompanies);

  /* @ngInject */
  function MdsqreApiCompanies(MdsqreApi, $q, MdsqreRemapper, ErrorModel, $log) {
    var service = {
      list: list,
      create: create,
      view: view,
      remove: remove
    };
    return service;

    ////////////////

    function create(company) {
      var data = {
        name: company.name,
        type: company.type,
        taxvat: company.taxvat,
        state_registry: company.state_registry,
        city_registry: company.city_registry,
        status: 'active',
        additional_info: company.additional_info,
        contact: {
          email: company.contact.email,
          phone1: company.contact.phone1,
          phone2: company.contact.phone2,
          twitter: company.contact.twitter,
          whatsapp: company.contact.whatsapp,
          facebook: company.contact.facebook,
          linkedin: company.contact.linkedin,
          website: company.contact.website,
          additional_info: company.additional_info
        },
        address: {
          gplace_id: company.address.gplace_id,
          name: company.address.name,
          street: company.address.street,
          number: company.address.number,
          district: company.address.district,
          complement: company.address.complement,
          city: company.address.city,
          zip: company.address.zip,
          state: company.address.state,
          country: company.address.country,
          additional_info: company.additional_info
        }
      };
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/companies', data)
        .then(function(companyResponse) {
          companyResponse.data.data = MdsqreRemapper.company(companyResponse.data.data);
          deferred.resolve(companyResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function list(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/companies')
        .then(function(companiesResponse) {
          var companies = [];
          companiesResponse.data.data.forEach(function(company) {
            companies.push(MdsqreRemapper.company(company));
          });
          companiesResponse.data.data = companies;
          deferred.resolve(companiesResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(companyId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/companies/' + companyId)
        .then(function(companyResponse) {
          companyResponse.data.data = MdsqreRemapper.company(companyResponse.data.data);
          deferred.resolve(companyResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(companyId) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/companies/' + companyId)
        .then(function(deleteResponse) {
          deferred.resolve(deleteResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('CompanyModel', CompanyModel);

  /* @ngInject */
  function CompanyModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor CompanyModel
     * @param {object}
     */
    function CompanyModel(data) {
      Model.call(this, data);
      this._class = 'CompanyModel';
      this.name = data.name;
      this.type = data.type;
      this.taxvat = data.taxvat;
      this.state_registry = data.state_registry;
      this.city_registry = data.city_registry;
      this.status = data.status;
      this.address = data.address;
      this.contact = data.contact;
      this.representor = data.representor;
      this.additional_info = data.additional_info;
      this.created_by = data.created_by;
      this.updated_by = data.updated_by;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
    }

    CompanyModel.prototype = Object.create(Model.prototype);

    return CompanyModel;
  }
})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('ContestationModel', ContestationModel);

  /* @ngInject */
  function ContestationModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor ContestationModel
     * @param {object}
     */
    function ContestationModel(data) {
      Model.call(this, data);
      this._class = 'ContestationModel';
      this.contestable_id = data.contestable_id;
      this.contestable_type = data.contestable_type;
      this.creator = data.creator;
      this.reason = data.description;
      this.description = data.description;
      this.proposal_info = data.proposal_info;
      this.agreed_at = data.agreed_at;
      this.proposal_at = data.proposal_at;
      this.proposal_created_at = data.proposal_at;
      this.resolved_at = data.resolved_at;
      this.resolver = data.resolver;
      this.comments = data.comments;
      this.subjects = data.subjects;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
      this.created_by = data.created_by;
      this.updated_by = data.updated_by;
      this.additional_info = data.additional_info;
      this.resolve_scheduled_at = data.resolve_scheduled_at;
    }

    ContestationModel.prototype = Object.create(Model.prototype);

    ContestationModel.prototype.canComment = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();

      if (this.isResolved()) {
        return false;
      }
      else if (context == 'uni' || context == 'int') {
        return false;
      } else if (context == 'pro') {
        if (this.isMyRole('auditor') || user.hasRole('auditor:super') || this.isMyRole('monitor')) {
          return false;
        }
        else if (this.isMyRole('attendant') &&
          !this.agreed_at) {
          return true;
        }
      }
      return false;
    };

    ContestationModel.prototype.canCommentResolution = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (context == 'pro') {
        return false;
      }
      if (context == 'int' || context == 'uni') {
        return true;
      }
    }

    ContestationModel.prototype.auditorIsSecret = function () {
      var context = MdsqreHelpers.getContext().short;
      if (context == 'pro') {
        return true;
      } else {
        return false;
      }
    }

    ContestationModel.prototype.canView = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();

      if (this.canComment() || this.canResolve() || this.canAgree() || this.canProposeSolution()) {
        return true;
      }

      if (context == 'pro') {
        if (this.isMyRole('monitor') || user.hasRole('auditor:super')) {
          return true;
        }
      }

      return false;
    };

    ContestationModel.prototype.canProposeSolution = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();

      if (context == 'uni' || context == 'int') {
        return false;
      } else if (context == 'pro') {
        if (user.hasRole('auditor:super') &&
          !this.isResolved() &&
          !this.proposal_at) {
          return true
        }
      }
      return false;
    }

    ContestationModel.prototype.canResolve = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (this.isResolved()) {
        return false;
      }
      if (context == 'uni' || context == 'int') {
        if (user.hasRole('manager')) {
          return true;
        }
      } else if (context == 'pro') {
        if (user.hasRole('auditor:super') &&
          !this.proposal_at) {
          return true;
        }
      }
      return false;
    };

    ContestationModel.prototype.canAgree = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (context == 'uni' || context == 'int') {
        return false;
      } else if (context == 'pro') {
        if (user.hasRole('auditor:super') && !(this.created_by == user.id)) {
          return false;
        } else if (this.isMyRole('attendant') &&
          this.proposal_at &&
          !this.agreed_at) {
          return true;
        }
        return false;
      }
      return false;
    };

    ContestationModel.prototype.belongsToMe = function () {
      var me = MdsqreAuth.getUser();
      if (this.created_by == me.id) return true;
      return false;
    };

    ContestationModel.prototype.isSubject = function (id) {
      if (!this.subjects || this.subjects.length <= 0) return false;
      if (this.subjects.find(function (subject) {
          return subject.id == id;
        })) {
        return true;
      }
      return false;
    }

    ContestationModel.prototype.getSubject = function (id) {
      if (!this.subjects || this.subjects.length <= 0) return false;
      var selectedSubject = this.subjects.find(function (subject) {
        return subject.id == id;
      });
      if (selectedSubject) return selectedSubject;
      return null;
    }

    ContestationModel.prototype.isResolved = function () {
      return this.resolved_at;
    }

    ContestationModel.prototype.isMyRole = function (role) {
      var user = MdsqreAuth.getUser();
      var subj = this.subjects.filter(function (subject) {
        if (subject != undefined)
          return subject.pivot && subject.pivot.role == role;
        else {
          return false;
        }
      });
      if (subj[0] != undefined) {
        return subj[0].pivot.user_id == user.id;
      }
      return false;
    }

    ContestationModel.prototype.getRemainingTime = function () {
      if (!this.resolve_scheduled_at) return false;

      var resolveAt = moment(this.resolve_scheduled_at);
      var now = moment();

      var hours = moment.duration(resolveAt.diff(now, 'hours'), 'hours').asHours();

      return hours;
    }

    return ContestationModel;
  }

})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiContestation', MdsqreApiContestation);

  /* @ngInject */
  function MdsqreApiContestation(MdsqreApi, MdsqreRemapper, $q) {
    var service = {
      create: create,
      view: view,
      comment: comment,
      agree: agree,
      resolve: resolve
    };
    return service;

    ////////////////

    function create(contestation) {
      var deferred = $q.defer();
      var data = {
        contestable_type: contestation.contestable_type || 'event',
        contestable_id: contestation.contestable_id || contestation.event_id,
        description: contestation.reason || contestation.description,
        additional_info: contestation.additional_info
      };
      MdsqreApi.request('POST', 'api/v1/events/' + data.contestable_id + '/contestations', data)
        .then(function (contestationResponse) {
          deferred.resolve(contestationResponse.data);
        })
        .catch(function (errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(contestation) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/events/' + contestation.event_id + '/contestations/' + contestation.contestation_id)
        .then(function (contestationResponse) {
          contestationResponse.data.data = MdsqreRemapper.contestation(contestationResponse.data.data);
          deferred.resolve(contestationResponse.data);
        })
        .catch(function (errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function comment(comment) {
      var deferred = $q.defer();
      var data = {
        contestable_id: comment.contestable_id || comment.event_id,
        contestation_id: comment.contestation_id,
        title: comment.title,
        body: comment.body,
        additional_info: comment.additional_info
      };
      MdsqreApi.request('POST', 'api/v1/events/' + data.contestable_id + '/contestations/' + data.contestation_id + '/comments', data)
        .then(function (contestationResponse) {
          deferred.resolve(contestationResponse.data);
        })
        .catch(function (errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function agree(agreement) {
      var deferred = $q.defer();
      var data = {
        contestable_id: agreement.contestable_id || agreement.event_id,
        contestation_id: agreement.contestation_id,
        comment: agreement.reason || agreement.comment,
      };
      MdsqreApi.request('POST', 'api/v1/events/' + data.contestable_id + '/contestations/' + data.contestation_id + '/agree', data)
        .then(function (agreementResponse) {
          deferred.resolve(agreementResponse.data);
        })
        .catch(function (errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function resolve(resolve) {
      var deferred = $q.defer();
      var data = {
        contestable_id: resolve.contestable_id || resolve.event_id,
        contestation_id: resolve.contestation_id,
        comment: resolve.reason || resolve.comment,
      };
      MdsqreApi.request('POST', 'api/v1/events/' + data.contestable_id + '/contestations/' + data.contestation_id + '/resolve', data)
        .then(function (contestationResponse) {
          deferred.resolve(contestationResponse.data);
        })
        .catch(function (errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('ContactModel', ContactModel);

  /* @ngInject */
  function ContactModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor ContactModel
     * @param {object}
     */
    function ContactModel(data) {
      Model.call(this, data);
      this._class = 'ContactModel';
      this.contactable_type = data.contactable_type;
      this.contactable_id = data.contactable_id;
      this.email = data.email;
      this.phone1 = data.phone1;
      this.phone2 = data.phone2;
      this.linkedin = data.linkedin;
      this.twitter = data.twitter;
      this.facebook = data.facebook;
      this.website = data.website;
      this.whatsapp = data.whatsapp;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
      this.created_by = data.created_by;
      this.updated_by = data.updated_by;
      this.additional_info = data.additional_info;
    }

    ContactModel.prototype = Object.create(Model.prototype);

    return ContactModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('ContractModel', ContractModel);

  /* @ngInject */
  function ContractModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor ContractModel
     * @param {object}
     */
    function ContractModel(data) {
      Model.call(this, data);
      this._class = 'ContractModel';
      this.type = data.type;
      this.budget_total = data.budget_total;
      this.budget_yearly = data.budget_yearly;
      this.budget_monthly = data.budget_monthly;
      this.validity_start = data.validity_start;
      this.validity_end = data.validity_end;
      this.validity_overtime = data.validity_overtime;
      this.organization_id = data.organization_id;
      this.subjects = data.subjects;
      this.items = data.items;
      this.child_contracts = data.child_contracts;
      this.additional_info = data.additional_info;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;

      // this.init();
    }

    ContractModel.prototype = Object.create(Model.prototype);

    ContractModel.prototype.init = function() {
      var budget = this.calcBudget(this.validity_start, this.validity_end, this.budget_total);
      this.budget_total = this.budget_total.toFixed(2);
      this.budget_yearly = budget.yearly;
      this.budget_monthly = budget.monthly;

      this.validity_start = new Date(moment(this.validity_start));
      this.validity_end = new Date(moment(this.validity_end));
      if (this.validity_overtime) {
        this.validity_overtime = new Date(moment(this.validity_overtime));
      }
    };

    ContractModel.prototype.getValidityStart = function(format) {
      if (!this.validity_start) throw new Error('validity_start not defined');
      if (format) return moment(this.validity_start).format(format);
      return moment(this.validity_start);
    };

    ContractModel.prototype.getValidityEnd = function(format) {
      if (!this.validity_end) throw new Error('validity_end not defined');
      if (format) return moment(this.validity_end).format(format);
      return moment(this.validity_end);
    };

    ContractModel.prototype.getYearlyBudget = function() {
      if (!this.validity_start) throw new Error('validity_start not defined');
      if (!this.validity_end) throw new Error('validity_end not defined');
      var budget = this.calcBudget(this.validity_start, this.validity_end, this.budget_total);
      return budget.yearly;
    };

    ContractModel.prototype.getMonthlyBudget = function() {
      if (!this.validity_start) throw new Error('validity_start not defined');
      if (!this.validity_end) throw new Error('validity_end not defined');
      var budget = this.calcBudget(this.validity_start, this.validity_end, this.budget_total);
      return budget.monthly;
    };

    ContractModel.prototype.calcBudget = function(start, end, budget) {
      var start = moment(start);
      var end = moment(end);
      var months = moment(end).diff(start, 'month', true);
      var years = moment(end).diff(start, 'year', true);
      return {
        yearly: (budget / years).toFixed(2),
        monthly: (budget / months).toFixed(2),
      };
    };

    ContractModel.prototype.getSecuritization = function() {
      if (!this.child_contracts) return false;
      var securitizationContract = this.child_contracts.find(function(contract) {
        return contract.type == 'securitization';
      });
      return securitizationContract;
    };

    ContractModel.prototype.hasSecuritization = function() {
      if (this.getSecuritization()) return true;
      return false;
    };

    return ContractModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiContracts', MdsqreApiContracts);

  /* @ngInject */
  function MdsqreApiContracts(MdsqreApi, $q, MdsqreRemapper, ErrorModel, $log) {
    var service = {
      list: list,
      create: create,
      view: view,
      remove: remove,
      securitize: securitize
    };
    return service;

    ////////////////

    function create(contract) {
      var data = {
        type: contract.type,
        title: contract.title,
        description: contract.description,
        budget_total: contract.budget_total,
        validity_start: contract.validity_start,
        validity_end: contract.validity_end,
        validity_overtime: contract.validity_overtime,
        additional_info: contract.additional_info
      };
      if (contract.subjects) {
        var subjects = [];
        contract.subjects.forEach(function(subject) {
          subjects.push({ subjectable_id: subject.id, subjectable_type: subject.type, role: subject.role });
        });
        data.subjects = subjects;
      }
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/contracts', data)
        .then(function(contractResponse) {
          contractResponse.data.data = MdsqreRemapper.contract(contractResponse.data.data);
          deferred.resolve(contractResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function list(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/contracts')
        .then(function(contractsResponse) {
          var contracts = [];
          contractsResponse.data.data.forEach(function(contract) {
            contracts.push(MdsqreRemapper.contract(contract));
          });
          contractsResponse.data.data = contracts;
          deferred.resolve(contractsResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(contractId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/contracts/' + contractId)
        .then(function(contractResponse) {
          contractResponse.data.data = MdsqreRemapper.contract(contractResponse.data.data);
          deferred.resolve(contractResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(contractId) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/contracts/' + contractId)
        .then(function(deleteResponse) {
          deferred.resolve(deleteResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function securitize(contract) {
      var data = {
        type: contract.type,
        title: contract.title,
        description: contract.description,
        budget_total: contract.budget_total,
        validity_start: contract.validity_start,
        validity_end: contract.validity_end,
        validity_overtime: contract.validity_overtime,
        additional_info: contract.additional_info
      };
      if (contract.subjects) {
        var subjects = [];
        contract.subjects.forEach(function(subject) {
          subjects.push({ subjectable_id: subject.id, subjectable_type: subject.type, role: subject.role });
        });
        data.subjects = subjects;
      }
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/contracts/'+contract.id+'/securitization', data)
        .then(function(contractResponse) {
          contractResponse.data.data = MdsqreRemapper.contract(contractResponse.data.data);
          deferred.resolve(contractResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('NotificationModel', NotificationModel);

  /* @ngInject */
  function NotificationModel(Model, MdsqreAuth) {

    ////////////////

    /**
     * @constructor NotificationModel
     * @param {object}
     */
    function NotificationModel(data) {
      Model.call(this, data);
      this._class = 'NotificationModel';
      this.data = data.data;
      this.notifiable_id = data.notifiable_id;
      this.notifiable_type = data.notifiable_type;
      this.read_at = data.read_at;
      this.type = data.type;
      this.updated_at = data.updated_at;
    }

    NotificationModel.prototype = Object.create(Model.prototype);

    NotificationModel.prototype.isRead = function() {
      if (this.read_at) return true;
      return false;
    };

    NotificationModel.prototype.getIcon = function() {
      if (this.type) {
        // this should not be here.
        if (this.type.indexOf('AssignedNotification') > -1) return 'assets/img/ico-new-shift.png';
        if (this.type.indexOf('StartingNotification') > -1) return 'assets/img/ico-checkin.png';
        if (this.type.indexOf('EndingNotification') > -1) return 'assets/img/ico-checkout.png';
        if (this.type.indexOf('RatebackNotification') > -1) return 'assets/img/ico-star.png';
      }
      return 'assets/img/icon-info.png';
    };

    NotificationModel.prototype.getAction = function() {
      if (this.type) {
        // this should not be here.
        if (this.type.indexOf('AssignedNotification') > -1) {
          return { type: 'redirect', route: 'interns.agenda' };
        }
        if (this.type.indexOf('StartingNotification') > -1) {
          return {
            type: 'redirect',
            route: 'interns.events.view',
            params: {
              eventId: this.data.event.id
            }
          };
        }
        if (this.type.indexOf('EndingNotification') > -1) {
          return {
            type: 'redirect',
            route: 'interns.events.view',
            params: {
              eventId: this.data.event.id
            }
          };
        }
        if (this.type.indexOf('RatebackNotification') > -1) {
          return { type: 'redirect', route: 'interns.agenda' };
        }
      }
      return false;
    };

    return NotificationModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiNotifications', MdsqreApiNotifications);

  /* @ngInject */
  function MdsqreApiNotifications(MdsqreApi, MdsqreRemapper, $q) {
    var service = {
      view: view,
    };
    return service;

    ////////////////

    function view(notificationId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/notifications/' + notificationId)
      .then(function(notificationResponse) {
        notificationResponse.data = MdsqreRemapper.notification(notificationResponse.data);
        deferred.resolve(notificationResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('EventModel', EventModel);

  /* @ngInject */
  function EventModel(Model, MdsqreAuth, MdsqreHelpers, EVENT_STATES, filterFilter) {

    ////////////////

    /**
     * @constructor EventModel
     * @param {object}
     */
    function EventModel(data) {
      Model.call(this, data);
      this._class = 'EventModel';
      this.state = data.state;
      this.title = data.title;
      this.description = data.description;
      this.color = data.color;
      this.location = data.location;
      this.specialties = data.specialties;
      this.attendants = data.attendants;
      this.subjects = data.subjects;
      this.start = data.start;
      this.end = data.end;
      this.period = data.period;
      this.eventable = data.eventable;
      this.contestations = data.contestations;
      this.validations = data.validations;
      this.ratings = data.ratings;
      this.eventable_type = data.eventable_type;
      this.presences = data.presences;
      this.service = data.service;
      this.offers = data.offers;
      this.offersv2 = data.offersv2;
      this.repeat = data.repeat;
      this.repeat_frequency = data.repeat_frequency;
      this.repeat_start = data.repeat_start;
      this.repeat_end = data.repeat_end;
      this.organization_id = data.organization_id;
      this.additional_info = data.additional_info;
      this.created_by = data.created_by;
      this.created_at = data.created_at;
      this.updated_by = data.updated_by;
      this.updated_at = data.updated_at;
    }

    EventModel.prototype = Object.create(Model.prototype);

    EventModel.prototype.getAttendants = function (nameString) {
      if (!this.attendants) return false;
      if (nameString) {
        var attendantsNames = '';
        this.attendants.forEach(function (attendant) {
          if (attendant && attendant.name) {
            attendantsNames += attendant.name + ' ';
          } else {
            attendantsNames += 'não definido';
          }
        });
        return attendantsNames;
      }
      return this.attendants;
    };

    EventModel.prototype.getSubjects = function (role) {
      if (!this.subjects) return false;
      if (role) {
        var roleSubjects = this.subjects.filter(function (subject) {
          return subject.pivot && subject.pivot.role == role;
        });
        return roleSubjects;
      }
      return this.subjects;
    };

    EventModel.prototype.getMonitors = function () {
      return this.getSubjects('monitor');
    };

    EventModel.prototype.getAuditors = function () {
      return this.getSubjects('auditor');
    };

    EventModel.prototype.hasAttendants = function (nameString) {
      if (!this.attendants || this.attendants.length <= 0) return false;
      if (this.attendants.length > 0) return true;
    };

    EventModel.prototype.getAttendant = function (id) {
      if (!this.attendants || this.attendants.length <= 0) return false;
      var attendants = this.attendants;
      var selected = null;
      attendants.forEach(function (attendant) {
        if (attendant.id == id && !selected) {
          selected = attendant;
        }
      });
      return selected;
    };

    EventModel.prototype.isAttendant = function (id) {
      var attendant = this.getAttendant(id);
      if (attendant && attendant.id) return true;
      return false;
    };

    EventModel.prototype.isMyRole = function (role) {
      var user = MdsqreAuth.getUser();
      var subj = this.subjects.filter(function (subject) {
        if (subject != undefined)
          return subject.pivot && subject.pivot.role == role;
        else {
          return false;
        }
      });
      if (subj[0] != undefined) {
        return subj[0].pivot.user_id == user.id;
      }
      return false;
    }

    EventModel.prototype.getState = function (remap) {
      if (this.eventable.state === 'assigned' && !this.isInProgress() && !this.hasFinished()) {
        return this.eventable.getState(remap);
      }
      if (this.eventable.state === 'canceled') {
        return this.eventable.getState(remap);
      }
      if (this.eventable.state === 'contested') {
        return this.eventable.getState(remap);
      }
      if (this.eventable.state === 'validated') {
        return this.eventable.getState(remap);
      }

      if (remap) return EVENT_STATES[this.state];
      return this.state;
    };

    EventModel.prototype.getDayPeriod = function () {
      if (!this.period) return false;
      return this.period;
    };

    EventModel.prototype.parseDayPeriod = function () {
      if (!this.start) throw new Error('Start not defined');
      if (!this.end) throw new Error('End not defined');

      return MdsqreHelpers.parseDayPeriod(this.start, this.end);
    };

    EventModel.prototype.getStart = function (format) {
      if (!this.start) throw new Error('Start not defined');
      if (format) return moment(this.start).format(format);
      return moment(this.start);
    };

    EventModel.prototype.getEnd = function (format) {
      if (!this.end) throw new Error('End not defined');
      if (format) return moment(this.end).format(format);
      return moment(this.end);
    };

    EventModel.prototype.getPaymentDate = function (format) {
      if (!this.service || !this.service.payment_date) return '';
      if (format) return moment(this.service.payment_date).format(format);
      return moment(this.service.payment_date);
    };

    EventModel.prototype.getDuration = function (output) {
      if (!this.start || !this.end) throw new Error('Event start/end not seted.');
      if (!output) output = 'h';
      return moment(this.end).diff(moment(this.start), output);
    };

    EventModel.prototype.wasCanceled = function () {
      if (this.canceled_at) return true;
      return false;
    };

    EventModel.prototype.atCheckinTime = function (timeOffset) {
      if (!this.start) throw new Error('Start not defined');
      if (!timeOffset) {
        var timeOffset = {
          minutes: 30
        };
      }
      var now = moment();
      if (moment(this.start).subtract(timeOffset).isBefore(now)) {
        return true;
      }
      return false;
    };

    EventModel.prototype.hasStarted = function () {
      if (!this.start) throw new Error('Start not defined');
      var now = moment();
      if (moment(this.start).isBefore(now)) {
        return true;
      }
      return false;
    };

    EventModel.prototype.isInProgress = function () {
      if (!this.start) throw new Error('Start not defined');
      if (!this.end) throw new Error('End not defined');
      var now = moment();
      if (moment(this.start).isBefore(now) && moment(this.end).isAfter(now)) {
        return true;
      }
      return false;
    };

    EventModel.prototype.hasFinished = function () {
      if (!this.start) throw new Error('Start not defined');
      if (!this.end) throw new Error('End not defined');

      if (moment(this.end).isBefore(moment())) return true;
      return false;
    };

    EventModel.prototype.canCheckin = function () {
      if (this.belongsToMe() &&
        this.atCheckinTime() &&
        !this.hasCheckin() &&
        !this.isContested() &&
        !this.eventable.isValidated()) {
        return true;
      }
      return false;
    };

    EventModel.prototype.canCheckout = function () {
      if (this.belongsToMe() &&
        this.hasFinished() &&
        !this.hasCheckout() &&
        !this.isContested() &&
        !this.eventable.isValidated()) {
        return true;
      }
      return false;
    };

    EventModel.prototype.canEvaluate = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();

      if (context == 'pro') {
        if (!this.hasConfirmedPresence() &&
          !this.belongsToMe() &&
          (( !this.hasCheckout() && this.timeToCheckoutExpired() ) || ( this.hasCheckout() )) &&
          !this.isContested() &&
          !this.eventable.isValidated() &&
          this.isMyRole('auditor')) {
            return true;
        }
        return false;
      }

      if (context == 'int' || context == 'uni') {
        if (!this.hasConfirmedPresence() &&
          !this.belongsToMe() &&
          this.hasCheckout() &&
          !this.isContested() &&
          !this.eventable.isValidated()) {
          var evaluator = this.subjects.find(function (subject) {
            return subject.pivot && subject.pivot.role == 'monitor' && subject.id == user.id;
          });
          if (evaluator) return true;
        }
        return false;
      }

    };

    EventModel.prototype.canRateAttendant = function () {
      if (this.canCheckout()) {
        return true;
      }
      if (this.canEvaluate()) {
        return true;
      }
      return false;
    }

    EventModel.prototype.canRateAuditor = function () {
      var context = MdsqreHelpers.getContext().short;
      if (context == 'pro') {
        return false;
      } else if (context == 'uni' || context == 'int') {
        if (this.canEvaluate() || this.canCheckout()) {
          return true;
        }
      }
      return false;
    }

    EventModel.prototype.canRateActivity = function () {
      if (this.canCheckout() || this.canEvaluate()) {
        return true;
      }
      return false;
    }

    EventModel.prototype.canViewActualValues = function () {
        if (this.belongsToMe() || this.hasBeenOfferedToMe() || this.isMyRole('monitor')) {
          return true;
        }
        return false;
    }

    EventModel.prototype.canViewAuditValues = function () {
      return !this.canViewActualValues();
    }

    EventModel.prototype.canViewCheckoutTime = function () {
      var context = MdsqreHelpers.getContext().short;
      if (context == 'int' || context == 'uni') {
        return true;
      } else if (context == 'pro') {
        if (this.isMyRole('auditor')) {
          return false;
        }
        return true;
      }
      return false;
    }

    EventModel.prototype.canViewCheckoutComment = function () {
      var context = MdsqreHelpers.getContext().short;
      if (context == 'int' || context == 'uni') {
        if (this.isMyRole('monitor')) {
          return false;
        }
        return true;
      } else if (context == 'pro') {
        if (this.isMyRole('auditor')) {
          return false;
        }
        return true;
      }
      return false;
    }

    EventModel.prototype.canViewValidationComment = function () {
      var context = MdsqreHelpers.getContext().short;
      if (context == 'int' || context == 'uni') {
        if (this.isMyRole('attendant') || this.isMyRole('medic')) {
          return false;
        }
        return true;
      } else if (context == 'pro') {
        return true;
      }
      return false;
    }

    EventModel.prototype.canViewAttendantRatings = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('attendant') || this.isMyRole('auditor') || user.hasRole('manager')) {
          return true;
        }
      } else if (context == 'pro') {
        if (this.isMyRole('attendant')) {
          return true;
        }
      }
      return false;
    }

    EventModel.prototype.canViewAuditorRatings = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('auditor') || user.hasRole('auditor:super')) {
          return true;
        }
      } else if (context == 'pro') {
        if (this.isMyRole('auditor')) {
          return true;
        }
      }
      return false;
    }

    EventModel.prototype.canViewMonitorRatings = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('monitor') || user.hasRole('auditor:super')) {
          return true;
        }
      } else if (context == 'pro') {
        if (this.isMyRole('monitor')) {
          return true;
        }
      }
      return false;
    }

    EventModel.prototype.canViewAttendantRateSelf = function () {

    }

    EventModel.prototype.canViewAttendantRateAuditor = function () {

    }

    EventModel.prototype.canViewAttendantRateActivity = function () {

    }

    EventModel.prototype.canViewAuditorRateAttendant = function () {

    }

    EventModel.prototype.canViewAuditorRateSelf = function () {

    }

    EventModel.prototype.canViewAuditorRateActivity = function () {

    }

    EventModel.prototype.canComment = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (!user) return false;

      if (this.eventable.isValidated()) {
        return false;
      }

      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('attendant') || this.isMyRole('auditor') || user.hasRole('auditor:super')) {
          return true;
        }
      } else if (context == 'pro') {
        if (this.isMyRole('attendant') || this.isMyRole('monitor')) {
          return true;
        }
      }
      return false;
    };

    EventModel.prototype.canViewComments = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();
      if (!user) return false;

      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('attendant') || this.isMyRole('auditor') || user.hasRole('auditor:super')) {
          return true;
        }
      } else if (context == 'pro') {
        if (this.isMyRole('attendant') || this.isMyRole('monitor')) {
          return true;
        }
      }
      return false;
    };

    EventModel.prototype.canContest = function () {
      var context = MdsqreHelpers.getContext().short;
      var user = MdsqreAuth.getUser();

      if (context == 'uni' || context == 'int') {
        if (this.isMyRole('monitor') &&
          !this.hasConfirmedPresence() &&
          !this.belongsToMe() &&
          this.hasCheckout() &&
          !this.isContested() &&
          !this.eventable.isValidated()) {
          var evaluator = this.subjects.find(function (subject) {
            return subject.pivot && subject.pivot.role == 'monitor' && subject.id == user.id;
          });
          if (evaluator) return true;
        }
      } else if (context == 'pro') {
        if (!this.belongsToMe() &&
          this.isMyRole('auditor') &&
          ( this.timeToCheckoutExpired() || this.hasCheckout() ) &&
          !this.eventable.isValidated() &&
          !this.isContested()) {
          return true;
        }
      }
      return false;
    };

    EventModel.prototype.isContested = function () {
      if (this.contestations.length > 0) {
        if (this.contestations.find(function (contestation) {
            return contestation.resolved_at === null;
          })) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    EventModel.prototype.getActiveContestation = function () {
      return this.contestations.find(function (contestation) {
        return contestation.resolved_at == null;
      });
    };

    EventModel.prototype.hasOffers = function () {
      if (!this.offers) return false;
      if (this.offers.length <= 0) return false;
      return true;
    };

    EventModel.prototype.hasPendingOffers = function () {
      if (!this.hasOffers()) return false;
      var pendingOffers = this.getPendingOffers();
      if (pendingOffers && pendingOffers.length > 0) return true;
      return false;
    };

    EventModel.prototype.getPendingOffers = function () {
      if (!this.hasOffers()) return false;
      var terminatedStates = ['finished', 'canceled'];
      var pending = [];
      var offers = this.offers;
      offers.forEach(function (offer) {
        if (terminatedStates.indexOf(offer.state) === -1) {
          pending.push(offer);
        }
      });
      if (pending.length > 0) return pending;
      return false;
    }

    EventModel.prototype.canOfferTransfer = function () {
      if (!this.canBeOffered()) return false;
      var allowed = ['pro', 'int'];
      if (!allowed.includes(MdsqreHelpers.getContext().short)) return false;
      if (this.belongsToMe() &&
        !this.hasFinished() &&
        !this.isInProgress() &&
        !this.isContested() &&
        !this.hasPendingOffers()) {
        return true;
      }
      return false;
    }

    EventModel.prototype.canOfferExchange = function () {
      if (!this.canBeOffered()) return false;
      if (this.belongsToMe() &&
        !this.hasFinished() &&
        !this.isInProgress() &&
        !this.isContested() &&
        !this.hasPendingOffers()) {
        return true;
      }
      return false;
    }

    EventModel.prototype.canBeOffered = function () {
      var allowed = ['pro', 'uni'];
      return allowed.includes(MdsqreHelpers.getContext().short);
    }

    EventModel.prototype.hasContested = function () {
      return this.isContested();
    }

    EventModel.prototype.hasPendingAction = function () {
      if (this.isContested()) {
        var contestation = this.getActiveContestation();
        return this.canCheckin() || this.canCheckout() || this.canEvaluate() || contestation.canProposeSolution() || contestation.canResolve() || contestation.canAgree();
      } else {
        return this.canCheckin() || this.canCheckout() || this.canEvaluate();
      }
    }

    EventModel.prototype.hasPaymentInfo = function () {
      if (this.service) {
        return this.service.summary.price > 0;
      }
      return false;
    }

    EventModel.prototype.getPresence = function (presenceType, formatTime) {
      if (!this.presences || !presenceType) return false;
      var presence = null;
      this.presences.forEach(function (_presence) {
        if (!presence && _presence.type === presenceType) {
          presence = _presence;
          return;
        }
      });
      if (presence && formatTime) {
        presence.time = moment(presence.time);
      }
      return presence;
    };

    EventModel.prototype.getRating = function (creator, type) {
      if (!this.ratings) return null;
      var ratings = null;
      switch (creator) {
        case 'attendant':
          ratings = this.getAttendantRatings();
          break;
        case 'monitor':
          ratings = this.getMonitorRatings();
          break;
        case 'auditor':
          ratings = this.getAuditorRatings();
          break;
      }
      if (!ratings || !ratings.length) {
        return null;
      }
      var ratingFound = ratings.filter(function (rating) {
        return rating.type == type;
      });

      if (!ratingFound[0]) {
        return null;
      }

      return ratingFound[0].rate;
    }

    EventModel.prototype.getAttendantRatings = function () {
      // todo: warning, could have many attendants. should by filtered
      // by id argument eventually instead of index 0.
      var attendant = this.getAttendants()[0];
      if (!attendant) return [];
      var ratings = this.ratings.filter(function (rating) {
        return rating.created_by == attendant.id;
      });

      return ratings;
    };

    EventModel.prototype.getMonitorRatings = function () {
      // todo: warning, could have many monitors. should by filtered
      // by id argument eventually instead of index 0.
      var monitor = this.getMonitors()[0];
      if (!monitor) return [];
      var ratings = this.ratings.filter(function (rating) {
        return rating.created_by == monitor.id;
      });

      return ratings;
    };

    EventModel.prototype.getAuditorRatings = function () {
      // todo: warning, could have many auditors. should by filtered
      // by id argument eventually instead of index 0.
      var auditor = this.getAuditors()[0];
      if (!auditor) return [];
      var ratings = this.ratings.filter(function (rating) {
        return rating.created_by == auditor.id;
      });

      return ratings;
    };

    EventModel.prototype.hasCheckin = function () {
      if (!this.presences) return false;
      var checkinCount = 0;
      this.presences.forEach(function (presence) {
        if (presence.type == 'checkin') checkinCount++;
      });
      if (checkinCount) return true;
      return false;
    };

    EventModel.prototype.hasCheckout = function () {
      if (!this.presences) return false;
      var checkoutCount = 0;
      this.presences.forEach(function (presence) {
        if (presence.type == 'checkout') checkoutCount++;
      });
      if (checkoutCount) return true;
      return false;
    };

    EventModel.prototype.hasConfirmedPresence = function () {
      if (!this.presences) return false;
      var confirmation = 0;
      this.presences.forEach(function (presence) {
        if (presence.type == 'presence_confirmation') confirmation++;
      });
      if (confirmation) return true;
      return false;
    };

    EventModel.prototype.belongsToMe = function () {
      var user = MdsqreAuth.getUser();
      if (this.isAttendant(user.id)) return true;
      return false;
    };

    // ### TODO - IMPLEMENT THIS! ###
    EventModel.prototype.hasBeenOfferedToMe = function () {
      return false;
    };

    EventModel.prototype.getCheckinInfo = function () {
      if (this.hasCheckin()) {
        var checkin = this.getPresence('checkin', true);
        this.checkinTime = checkin.time.format('D MMM, ddd | HH:mm');
      };
    };

    EventModel.prototype.getCheckoutInfo = function () {
      if (this.hasCheckout()) {
        var checkoutPresence = this.getPresence('checkout', true);
        this.checkoutTime = checkoutPresence.time.format('D MMM, ddd | HH:mm');
        if (checkoutPresence.additional_info.avaliator) {
          this.monitorName = checkoutPresence.additional_info.avaliator.name;
          this.attendantEvaluatorRating = checkoutPresence.additional_info.rating.avaliator.rate;
        } else if (checkoutPresence.additional_info.monitor) {
          this.monitorName = checkoutPresence.additional_info.monitor.name;
          this.attendantEvaluatorRating = checkoutPresence.additional_info.rating.avaliator.rate;
        }
        this.attendantEventRating = checkoutPresence.additional_info.rating.event.rate;
        this.Activities = checkoutPresence.additional_info.tags.join(', ');
      }
    }

    EventModel.prototype.getConfirmationInfo = function () {
      if (this.hasConfirmedPresence()) {

        var presenceConfirmation = this.getPresence('presence_confirmation', true);
        this.confirmationTime = presenceConfirmation.time.format('D MMM, ddd | HH:mm');

        if (this.validations[0] && this.validations[0].validator) {
          this.evaluatorName = this.validations[0].validator.name;
        }

        var resolvedContestation = filterFilter(this.contestations, {
          resolved_at: ''
        });
        if (resolvedContestation && resolvedContestation.length > 0) {
          var lastComment = resolvedContestation[0].comments.length - 1;
          this.confirmationObs = resolvedContestation[0].comments[lastComment] ? resolvedContestation[0].comments[lastComment].body : '';
        } else {
          if (presenceConfirmation.additional_info)
            this.confirmationObs = presenceConfirmation.additional_info.obs;
        }

        var self = this;

        // in case of validation by monitor
        var monitorRatings = this.getMonitorRatings();
        if (monitorRatings && monitorRatings.length > 0) {
          monitorRatings.forEach(function (rating) {
            if (rating.type == 'event') self.evaluatorEventRating = rating.rate;
            if (rating.type == 'intern_performance') self.evaluatorAttendantRating = rating.rate;
          });
        }


        // in case of validation by contestation resolve (auditor)
        var auditorRatings = this.getAuditorRatings();
        if (auditorRatings && auditorRatings.length > 0) {
          auditorRatings.forEach(function (rating) {
            if (rating.type == 'event') self.evaluatorEventRating = rating.rate;
            if (rating.type == 'intern_performance') self.evaluatorAttendantRating = rating.rate;
          });
        }
      }
    }

    EventModel.prototype.timeToCheckoutExpired = function () {
      var now = moment();
      var numHours = 1;
      var finish = moment(this.end, "YYYY/MM/DD hh:mm:ss");
      if (now.isAfter(moment(finish).add(numHours, "hours"))) {
        return true;
      }
      return false;
    }

    return EventModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiEvents', MdsqreApiEvents);

  /* @ngInject */
  function MdsqreApiEvents(MdsqreApi, MdsqreRemapper, $q) {
    var service = {
      list: list,
      view: view,

      rating: rating,
      presence: presence,
    };
    return service;

    ////////////////

    function list(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/events', params: params })
      .then(function(eventsResponse) {
        var events = [];
        eventsResponse.data.data.forEach(function(event) {
          events.push(MdsqreRemapper.event(event));
        });
        eventsResponse.data.data = events;
        deferred.resolve(eventsResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function view(eventId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/events/' + eventId)
      .then(function(eventResponse) {
        eventResponse.data.data = MdsqreRemapper.event(eventResponse.data.data);
        deferred.resolve(eventResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function presence(presence, publicRoute) {
      var deferred = $q.defer();
      presence.user_id = presence.attendant_id || presence.user_id;
      var url = 'api/v1/events/'+presence.event_id+'/presences';
      if (publicRoute) {
        url = 'api/v1/public/events/'+presence.event_id+'/presences';
      }
      MdsqreApi.request('POST', url, presence)
      .then(function(presenceResponse) {
        // presenceResponse.data.data = new EventModel(presenceResponse.data.data);
        deferred.resolve(presenceResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function rating(rating) {
      var deferred = $q.defer();
      var data = { ratings: rating.ratings };
      MdsqreApi.request('POST', 'api/v1/events/'+rating.event_id+'/ratings', data)
      .then(function(ratingResponse) {
        ratingResponse.data.data = MdsqreRemapper.rating(ratingResponse.data.data);
        deferred.resolve(ratingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

  }
})();

// should deprecate
(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiEventOffers', MdsqreApiEventOffers);

  /* @ngInject */
  function MdsqreApiEventOffers(MdsqreApi, MdsqreApiOffers, MdsqreApiTransactions, MdsqreRemapper, $q, $log) {
    var service = {
      list: list,
      view: view,
      create: create,
      remove: remove,
      accept: accept,
      reject: reject,

      confirm: confirm, // deprecated, use cancelTransaction instead
      cancel: cancel, // deprecated, use cancelTransaction instead
      authorize: authorize,     // deprecated, use denyTransaction instead
      deny: deny,     // deprecated, use denyTransaction instead

      confirmTransaction: confirmTransaction,
      cancelTransaction: cancelTransaction,
      authorizeTransaction: authorizeTransaction,
      denyTransaction: denyTransaction,
    };
    return service;

    ////////////////

    function list(params) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.list(params);
    }

    function view(offerId) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.view(offerId);
    }

    function create(offer) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      var data = {
        offerable_id: offer.event_id || offer.offerable_id,
        additional_info: offer.additional_info,
        type: offer.transaction_type || offer.type,
        additional_info: offer.additional_info
      }
      if (offer.recipients) {
        data.subjects = offer.recipients;
      }
      if (data.type) {
        if (['transfer', 'App\\OfferTransfer'].indexOf(data.type) > -1) { data.type = 'transfer' };
        if (['exchange', 'App\\OfferExchange'].indexOf(data.type) > -1) { data.type = 'exchange' };
      }
      return MdsqreApiOffers.create(data);
    }

    function remove(offerId) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.remove(offerId);
    }

    function accept(offer) {
      var deferred = $q.defer();
      var data = {
        state: offer.state || 'accepted',
        offerable_id: offer.event_id || offer.offerable_id,
        to_offerable_id: offer.to_event_id || offer.to_offerable_id,
        to_attendant: offer.to_attendant,
        additional_info: offer.additional_info
      };
      if (!offer.id) offer.id = offer.offer_id;
      return MdsqreApiOffers.accept(offer.id, data);
    }

    function reject(offer) {
      var deferred = $q.defer();
      var data = {
        state: offer.state || 'rejected',
        offerable_id: offer.event_id || offer.offerable_id,
        to_offerable_id: offer.to_event_id || offer.to_offerable_id,
        to_attendant: offer.to_attendant,
        additional_info: offer.additional_info
      };
      if (!offer.id) offer.id = offer.offer_id;
      return MdsqreApiOffers.reject(offer.id, data);
    }

    function confirm(offer) {
      return this.confirmTransaction(offer);
    }

    function cancel(offer) {
      return this.cancelTransaction(offer);
    }

    function authorize(offer) {
      return this.authorizeTransaction(offer);
    }

    function deny(offer) {
      return this.denyTransaction(offer);
    }

    function acceptTransaction(offer) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return this.accept(offer);
    }

    function rejectTransaction(offer) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return this.reject(offer);
    }

    function confirmTransaction(transaction) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.confirmTransaction({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function cancelTransaction(transaction) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.cancelTransaction({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function authorizeTransaction(transaction) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.authorizeTransaction({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function denyTransaction(transaction) {
      $log.warn('~~~ Warning: `MdsqreApiEventOffers` is deprecated, use `MdsqreApiOffers` ~~~');
      return MdsqreApiOffers.denyTransaction({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('OfferModel', OfferModel);

  /* @ngInject */
  function OfferModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor OfferModel
     * @param {object}
     */
    function OfferModel(data) {
      Model.call(this, data);
      this._class = 'OfferModel';
      this.state = data.state;
      this.type = data.type;
      this.offerable_type = data.offerable_type;
      this.offerable_id = data.offerable_id;
      this.offerable = data.offerable;
      this.transaction_type = data.type;
      this.transaction = data.transaction;
      this.transactions = data.transactions;
      this.subjects = data.subjects;
      this.created_by = data.created_by;
      this.creator = data.creator;
      this.additional_info = data.additional_info;
      this.event = data.event;
    }

    OfferModel.prototype = Object.create(Model.prototype);

    OfferModel.prototype.canAccept = function() {
      if (this.checkSubject(MdsqreAuth.getUser().id)
        && !this.getMyTransaction()
        && !this.belongsToMe()) {
        return true;
      }
      return false;
    };

    OfferModel.prototype.canReject = function() {
      if (this.checkSubject(MdsqreAuth.getUser().id)
        && !this.getMyTransaction()
        && !this.belongsToMe()) {
        return true;
      }
      return false;
    };

    OfferModel.prototype.canAuthorize = function(transactionId) {
      if (MdsqreAuth.getUser().hasRole('manager')
        && this.hasTransactions()
        && !this.isTransactionRejected(transactionId)
        && !this.isTransactionCanceled(transactionId)
        && !this.isTransactionAuthorized(transactionId)
        && !this.isTransactionDenied(transactionId)) {
        return true;
      }
      return false;
    };

    OfferModel.prototype.canDeny = function(transactionId) {
      if (MdsqreAuth.getUser().hasRole('manager')
        && this.hasTransactions()
        && !this.isTransactionRejected(transactionId)
        && !this.isTransactionCanceled(transactionId)
        && !this.isTransactionAuthorized(transactionId)
        && !this.isTransactionDenied(transactionId)) {
        return true;
      }
      return false;
    };

    OfferModel.prototype.canCancel = function() {
      if (this.belongsToMe() && this.state != 'canceled' && this.state != 'finished') {
        return true;
      }
      return false;
    }

    OfferModel.prototype.belongsToMe = function() {
      if (this.created_by == MdsqreAuth.getUser().id) {
        return true;
      }
      return false;
    }

    OfferModel.prototype.checkSubject = function(id, type) {
      if (!this.subjects || this.subjects.length == 0) return true;
      if (!type) type = 'App\\User';
      var subjects = this.subjects;
      var checkedSubjects = [];
      subjects.forEach(function(subject) {
        if (subject.id == id) {
          checkedSubjects.push(subject);
        }
      });
      if (checkedSubjects.length > 0) {
        return true;
      }
      return false;
    };

    OfferModel.prototype.getMyTransaction = function() {
      if (!this.hasTransactions()) return false;
      var userId = MdsqreAuth.getUser().id;
      return this.transactions.find(function(transaction) {
        return (transaction.to_attendant_id == userId) || (transaction.from_attendant_id == userId);
      });
    };

    OfferModel.prototype.hasTransactions = function() {
      if (this.transactions && this.transactions.length > 0) return true;
      return false;
    };

    OfferModel.prototype.hasAcceptedTransactions = function() {
      if (this.hasTransactions) {
        return this.transactions.find(function(transaction) {
          return transaction.state == 'accepted';
        });
      }
      return false;
    };

    OfferModel.prototype.getTransaction = function(id) {
      if (!this.hasTransactions()) return false;
      var transactions = this.transactions;
      var selectedTransaction = null;
      transactions.forEach(function(transaction) {
        if (!selectedTransaction && transaction.id == id) {
          selectedTransaction = transaction;
        }
      });
      return selectedTransaction;
    };

    OfferModel.prototype.getActiveTransaction = function() {
      if (this.transaction) return this.transaction;
      return false;
    };

    OfferModel.prototype.isOfferCanceled = function() {
      if (this.state === 'canceled') return true;
      return false;
    };

    OfferModel.prototype.isOfferFinished = function() {
      if (this.state === 'finished') return true;
      return false;
    };

    OfferModel.prototype.isTransactionAccepted = function(transactionId) {
      var transaction = this.getTransaction(transactionId);
      if (transaction.state === 'created') return true;
      return false;
    };

    OfferModel.prototype.isTransactionRejected = function(transactionId) {
      var transaction = this.getTransaction(transactionId);
      if (transaction.state === 'rejected') return true;
      return false;
    };

    OfferModel.prototype.isTransactionCanceled = function(transactionId) {
      var transaction = this.getTransaction(transactionId);
      if (transaction.state === 'canceled') return true;
      return false;
    };

    OfferModel.prototype.isTransactionAuthorized = function(transactionId) {
      var transaction = this.getTransaction(transactionId);
      if (transaction.state === 'authorized') return true;
      return false;
    };

    OfferModel.prototype.isTransactionDenied = function(transactionId) {
      var transaction = this.getTransaction(transactionId);
      if (transaction.state === 'denied') return true;
      return false;
    };

    return OfferModel;
  }
})();

// should deprecate
(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiOffers', MdsqreApiOffers);

  /* @ngInject */
  function MdsqreApiOffers(MdsqreApi, MdsqreApiTransactions, MdsqreRemapper, $q) {
    var service = {
      create: create,
      list: list,
      view: view,
      accept: accept,
      reject: reject,
      cancel: cancel,
      remove: remove,

      confirmTransaction: confirmTransaction,
      cancelTransaction: cancelTransaction,
      authorizeTransaction: authorizeTransaction,
      denyTransaction: denyTransaction,
    };
    return service;

    ////////////////

    function list(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/offers')
        .then(function(offersResponse) {
          var offers = [];
          offersResponse.data.data.forEach(function(offer) {
            // offers.push(new ShiftModel(offer));
            offers.push(MdsqreRemapper.offer(offer));
          });
          offersResponse.data.data = offers;
          deferred.resolve(offersResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(offerId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/offers/' + offerId)
        .then(function(offerResponse) {
          offerResponse.data.data = MdsqreRemapper.offer(offerResponse.data.data);
          deferred.resolve(offerResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function create(offer) {
      var deferred = $q.defer();
      var data = {
        offerable_type: 'App\\Event',
        offerable_id: offer.offerable_id,
        type: 'transfer',
        additional_info: offer.additional_info,
      };
      if (offer.offerable_type) {
        data.offerable_type = offer.offerable_type;
      }
      if (offer.type) {
        if (offer.type === 'transfer') { data.type = 'transfer' };
        if (offer.type === 'exchange') { data.type = 'exchange' };
      }
      if (offer.subjects) {
        var subjects = [];
        offer.subjects.forEach(function(subject) {
          if (subject.type === 'user') subjects.push({ subjectable_type: 'App\\User', subjectable_id: subject.id });
          if (subject.type === 'group') subjects.push({ subjectable_type: 'App\\Group', subjectable_id: subject.id });
        });
        data.subjects = subjects;
      }
      MdsqreApi.request('POST', 'api/v1/offers', data)
        .then(function(offerResponse) {
          deferred.resolve(offerResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function accept(id, accept) {
      var deferred = $q.defer();
      var data = {
        state: 'accepted',
        offerable_id: accept.offerable_id,
        to_offerable_id: accept.to_offerable_id,
        to_attendant: accept.to_attendant,
        additional_info: accept.additional_info
      };
      MdsqreApi.request('POST', 'api/v1/offers/'+id+'/accept', data)
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function reject(id, reject) {
      var deferred = $q.defer();
      var data = {
        state: 'rejected',
        offerable_id: reject.offerable_id,
        to_offerable_id: reject.to_offerable_id,
        to_attendant: reject.to_attendant,
        additional_info: reject.additional_info
      };
      MdsqreApi.request('POST', 'api/v1/offers/'+id+'/reject', data)
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function cancel(id) {
      $log.warn('MdsqreApiOffers.cancel() not implemented, using remove instead');
      return this.remove(id);
    }

    function remove(id) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/offers/'+id)
        .then(function(deleteResponse) {
          deferred.resolve(deleteResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function confirmTransaction(transaction) {
      return MdsqreApiTransactions.confirm({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function cancelTransaction(transaction) {
      return MdsqreApiTransactions.cancel({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function authorizeTransaction(transaction) {
      return MdsqreApiTransactions.authorize({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }

    function denyTransaction(transaction) {
      return MdsqreApiTransactions.deny({
        id: transaction.id || transaction.transaction_id,
        offer_id: transaction.offer_id,
        additional_info: transaction.additional_info
      });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('Offerv2Model', Offerv2Model);

  /* @ngInject */
  function Offerv2Model(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor Offerv2Model
     * @param {object}
     */
    function Offerv2Model(data) {
      Model.call(this, data);
      this._class = 'Offerv2Model';
      this.state = data.state;
      this.type = data.type;
      this.proposals = data.proposals;
      this.from_user = data.from_user;
      this.from_user_id = data.from_user_id;
      this.to_user = data.to_user;
      this.to_user_id = data.to_user_id;
      this.subjects = data.subjects;
      this.verifications = data.verifications;
      this.created_by = data.created_by;
      this.additional_info = data.additional_info;
    }

    Offerv2Model.prototype = Object.create(Model.prototype);

    Offerv2Model.prototype.findSubject = function(subjectId) {
      if (!this.subjects || this.subjects.length <= 0) return false;
      var subject = this.subjects.find(function(subject) {
        return subject.id == subjectId;
      });
      return subject;
    };

    Offerv2Model.prototype.getSubjectOfferRole = function(subjectId) {
      var subject = this.findSubject(subjectId);
      if (subject && subject.pivot && subject.pivot.role) {
        return subject.pivot.role;
      }
      return null;
    };

    return Offerv2Model;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('RatingModel', RatingModel);

  /* @ngInject */
  function RatingModel(Model, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor RatingModel
     * @param {object}
     */
    function RatingModel(data) {
      Model.call(this, data);
      this._class = 'RatingModel';
      this.type = data.type;
      this.rate = data.rate;
      this.rateable_type = data.rateable_type;
      this.rateable_id = data.rateable_id;
      this.user_id = data.user_id;
      this.additional_info = data.additional_info;
    }

    RatingModel.prototype = Object.create(Model.prototype);



    return RatingModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiRatings', MdsqreApiRatings);

  /* @ngInject */
  function MdsqreApiRatings(MdsqreApi, $q, RatingModel, ErrorModel) {
    var service = {
      create: create,
    };
    return service;

    ////////////////

    function create(rating) {
      var deferred = $q.defer();
      var data = {
        type: rating.type,
        rate: rating.rate,
        rateable_type: rating.rateable_type,
        rateable_id: rating.rateable_id,
        additional_info: rating.additional_info,
      };
      MdsqreApi.request('POST', 'api/v1/ratings', data)
      .then(function(ratingResponse) {
        ratingResponse.data.data = new RatingModel(ratingResponse.data.data);
        deferred.resolve(ratingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('RoleModel', RoleModel);

  /* @ngInject */
  function RoleModel(Model) {

    ////////////////

    /**
     * @constructor RoleModel
     * @param {object}
     */
    function RoleModel(data) {
      Model.call(this, data);
      this._class = 'RoleModel';
      this.name = data.name;
    }

    RoleModel.prototype = Object.create(Model.prototype);

    return RoleModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiRoles', MdsqreApiRoles);

  /* @ngInject */
  function MdsqreApiRoles(MdsqreApi, $q, RoleModel, ErrorModel) {
    var service = {
      list: list,
    };
    return service;

    ////////////////

    function list() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/system/roles')
      .then(function(rolesResponse) {
        var roles = [];
        rolesResponse.data.data.forEach(function(role) {
          roles.push(new RoleModel(role));
        });
        rolesResponse.data.data = roles;
        deferred.resolve(rolesResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiSettings', MdsqreApiSettings);

  /* @ngInject */
  function MdsqreApiSettings(MdsqreApi, $q, ErrorModel) {
    var service = {
      create: create,
      list: list,
      view: view,
      update: update,
    };
    return service;

    ////////////////

    function create(setting) {
      var deferred = $q.defer();
      var data = {
        key: setting.key,
        value: setting.value,
      };
      MdsqreApi.request('POST', 'api/v1/settings', data)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function list() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/settings')
      .then(function(settingsResponse) {
        var settings = [];
        settingsResponse.data.data.forEach(function(setting) {
          // do something
          settings.push(setting);
        });
        settingsResponse.data.data = settings;
        deferred.resolve(settingsResponse.data);
      })
      .catch(function(errorResponse) {
        console.log(errorResponse);
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function view(id) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/settings/'+id)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function update(setting) {
      var deferred = $q.defer();
      var data = {
        key: setting.key,
        value: setting.value,
      };
      MdsqreApi.request('PUT', 'api/v1/settings/'+setting.id, data)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('ShiftModel', ShiftModel);

  /* @ngInject */
  function ShiftModel(Model, MdsqreAuth, SHIFT_EVENT_STATES) {

    ////////////////

    /**
     * @constructor ShiftModel
     * @param {object}
     */
    function ShiftModel(data) {
      Model.call(this, data);
      this._class = 'ShiftModel';
      this.state = data.state;
      this.hostpital_id = data.hostpital_id;
      this.hospital_unit = data.hospital_unit;
      this.hospital_sector = data.hospital_sector;
      this.hospital_area = data.hospital_area;
      this.assigned_user = data.assigned_user;
      this.created_by = data.created_by;
      this.created_at = data.created_at;
      this.updated_by = data.updated_by;
      this.updated_at = data.updated_at;
      this.additional_info = data.additional_info;
      this.event = data.event;
    }

    ShiftModel.prototype = Object.create(Model.prototype);

    ShiftModel.prototype.getState = function(remap) {
      if (remap) return SHIFT_EVENT_STATES[this.state];
      return this.state;
    };

    ShiftModel.prototype.isContested = function() {
      if (this.state === 'contested') return true;
      return false;
    };

    ShiftModel.prototype.isValidated = function() {
      if (this.state === 'validated') return true;
      return false;
    };

    ShiftModel.prototype.canContest = function() {
      if (!this.isContested() && MdsqreAuth.getUser().hasRole('monitor')) return true;
      return false;
    };

    ShiftModel.prototype.getStart = function(format) {
      if (!this.event.start) throw new Error('Event start not seted.');
      if (!format) format = this.dateFormat;
      return moment(this.event.start).format(format);
    };

    ShiftModel.prototype.getEnd = function(format) {
      if (!this.event.end) throw new Error('Event end not seted.');
      if (!format) format = this.dateFormat;
      return moment(this.event.end).format(format);
    };

    ShiftModel.prototype.getDuration = function() {
      if (!this.event.start || !this.event.end) throw new Error('Event start/end not seted.');
      console.log(this.event.end);
      console.log(this.event.start);
      return moment(this.event.end).diff(moment(this.event.start), 'h');
    };

    return ShiftModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiShifts', MdsqreApiShifts);

  /* @ngInject */
  function MdsqreApiShifts(MdsqreApi, $q, MdsqreRemapper, ErrorModel) {
    var service = {
      list: list,
      create: create,
      view: view,
      update: update,
      remove: remove,

      cancel: cancel,
      contest: contest,
      contestResolve: contestResolve,
    };
    return service;

    ////////////////

    function list() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/shifts')
        .then(function(shiftsResponse) {
          var shifts = [];
          shiftsResponse.data.data.forEach(function(shift) {
            // shifts.push(new ShiftModel(shift));
            shifts.push(MdsqreRemapper.shiftEvent(shift));
          });
          shiftsResponse.data.data = shifts;
          deferred.resolve(shiftsResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function create(shift) {
      var deferred = $q.defer();
      var data = {
        hospital_id: shift.hospital_id,
        hospital_unit: shift.hospital_unit,
        hospital_sector: shift.hospital_sector,
        hospital_area: shift.hospital_area,
        assigned_user: shift.assigned_user.id,
        additional_info: {
          start: shift.event.start
        },
        event: {
          location: shift.event.location,
          start: shift.event.start,
          end: shift.event.end,
          period: shift.event.period,
          user_id: shift.assigned_user.id,
          recurring: shift.event.recurring,
        }
      }
      MdsqreApi.request('POST', 'api/v1/shifts', data)
        .then(function(shiftResponse) {
          // should do it in a cleaner way
          if (!data.event.recurring) {
            shiftResponse.data.data = MdsqreRemapper.shiftEvent(shiftResponse.data.data);
          }
          deferred.resolve(shiftResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(shiftId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/shifts/' + shiftId)
        .then(function(shiftResponse) {
          // shiftResponse.data.data = new ShiftModel(shiftResponse.data.data);
          shiftResponse.data.data = MdsqreRemapper.shiftEvent(shiftResponse.data.data);
          deferred.resolve(shiftResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function update(shift) {
      var deferred = $q.defer();
      var data = {
        hospital_id: shift.hospital_id,
        hospital_unit: shift.hospital_unit,
        hospital_sector: shift.hospital_sector,
        hospital_area: shift.hospital_area,
        // assigned_user: shift.assigned_user.id,
        additional_info: shift.additional_info,
        event: {
          location: shift.event.location,
          start: shift.event.start,
          end: shift.event.end,
          period: shift.event.period,
        }
      }
      MdsqreApi.request('PUT', 'api/v1/shifts/' + shift.id, data)
        .then(function(shiftResponse) {
          // shiftResponse.data.data = new ShiftModel(shiftResponse.data.data);
          shiftResponse.data.data = MdsqreRemapper.shiftEvent(shiftResponse.data.data);
          deferred.resolve(shiftResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(shiftId) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/shifts/' + shiftId)
        .then(function(deleteResponse) {
          deferred.resolve(deleteResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function cancel(shift) {
      var deferred = $q.defer();
      var data = { reason: shift.cancel_reason };
      MdsqreApi.request('POST', 'api/v1/shifts/' + shift.id + '/cancel', data)
        .then(function(cancelResponse) {
          deferred.resolve(cancelResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function contest(shift) {
      var deferred = $q.defer();
      var data = { reason: shift.contest_reason };
      MdsqreApi.request('POST', 'api/v1/shifts/' + shift.id + '/contest', data)
        .then(function(contestResponse) {
          deferred.resolve(contestResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function contestResolve(shift) {
      var deferred = $q.defer();
      var data = { solution: shift.contest_solution };
      MdsqreApi.request('POST', 'api/v1/shifts/' + shift.id + '/contest/resolve', data)
        .then(function(contestResponse) {
          deferred.resolve(contestResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('SpecialtyModel', SpecialtyModel);

  /* @ngInject */
  function SpecialtyModel(Model) {

    ////////////////

    /**
     * @constructor SpecialtyModel
     * @param {object}
     */
    function SpecialtyModel(data) {
      Model.call(this, data);
      this._class = 'SpecialtyModel';
      this.title = data.title;
      this.description = data.description;
      this.field = data.field;
      this.additional_info = data.additional_info;
    }

    SpecialtyModel.prototype = Object.create(Model.prototype);

    return SpecialtyModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiSpecialties', MdsqreApiSpecialties);

  /* @ngInject */
  function MdsqreApiSpecialties(MdsqreApi, $q, SpecialtyModel, ErrorModel) {
    var service = {
      list: list,
    };
    return service;

    ////////////////

    /*function list() {
      var deferred = $q.defer();

      MdsqreApi.request('GET', 'api/v1/specialties')
      .then(function(specialtiesResponse) {
        deferred.resolve(specialtiesResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }*/

  }
})();

(function () {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('TransactionModel', TransactionModel);

  /* @ngInject */
  function TransactionModel(Model, MdsqreAuth, MdsqreHelpers) {

    ////////////////

    /**
     * @constructor TransactionModel
     * @param {object}
     */
    function TransactionModel(data) {
      Model.call(this, data);
      this._class = 'TransactionModel';
      this.offer_id = data.offer_id;
      this.state = data.state;
      this.transactionable_type = data.transactionable_type;
      this.transactionable_id = data.transactionable_id;
      this.transactionable = data.transactionable;
      this.from_attendant_id = data.from_attendant_id;
      this.from_attendant = data.from_attendant;
      this.from_approved = data.from_approved;
      this.to_attendant_id = data.to_attendant_id;
      this.to_attendant = data.to_attendant;
      this.to_approved = data.to_approved;
      this.accepted_at = data.accepted_at;
      this.rejected_at = data.rejected_at;
      this.confirmed_at = data.confirmed_at;
      this.canceled_at = data.canceled_at;
      this.authorized_at = data.authorized_at;
      this.denied_at = data.denied_at;
      this.finished_at = data.finished_at;
      this.additional_info = data.additional_info;
      this.summary = null;
    };

    TransactionModel.prototype = Object.create(Model.prototype);

    TransactionModel.prototype.canCancel = function () {
      var allowed = [this.from_attendant_id, this.to_attendant_id];
      if (allowed.indexOf(MdsqreAuth.getUser().id) <= -1) return false;
      if (!this.isRejected() &&
        !this.isCanceled() &&
        !this.isAuthorized() &&
        !this.isDenied()) {
        return true;
      }
      return false;
    };

    TransactionModel.prototype.canConfirm = function () {
      if (MdsqreAuth.getUser().id == this.from_attendant_id &&
        !this.isRejected() &&
        !this.isConfirmed() &&
        !this.isCanceled() &&
        !this.isAuthorized() &&
        !this.isDenied()) {
        return true;
      }
      return false;
    };

    TransactionModel.prototype.canAuthorize = function () {
      if (MdsqreAuth.getUser().hasRole('manager') &&
        !this.isRejected() &&
        !this.isCanceled() &&
        !this.isAuthorized() &&
        !this.isDenied() &&
        this.isConfirmed()) {
        return true;
      }
      return false;
    };

    TransactionModel.prototype.canDeny = function () {
      if (MdsqreAuth.getUser().hasRole('manager') &&
        !this.isRejected() &&
        !this.isCanceled() &&
        !this.isAuthorized() &&
        !this.isDenied() &&
        this.isConfirmed()) {
        return true;
      }
      return false;
    };

    TransactionModel.prototype.getSummary = function () {
      var summary = {};
      summary.type = this.transactionable_type;
      summary.state = this.state;
      summary.viewingAs = this.viewingAs();
      summary.canCancel = this.canCancel();
      summary.canConfirm = this.canConfirm();
      summary.canAuthorize = this.canAuthorize();
      summary.canDeny = this.canDeny();
      summary.messages = this.getMessages();
      return summary;
    };

    TransactionModel.prototype.getMessages = function () {
      var messages = {
        hasDone: '',
        mustDo: ''
      };
      switch (this.viewingAs()) {

        case 'originator':
          if (this.isAccepted()) {
            messages.hasDone = '';//this.to_attendant.name + ' aceitou';
            messages.mustDo = 'Aguarda sua confirmação';
          } else if (this.isRejected()) {
            messages.hasDone = this.to_attendant.name + ' rejeitou sua oferta';
            messages.mustDo = '';
          } else if (this.isConfirmed()) {
            if (this.transactionable_type == 'exchange') {
              messages.hasDone = 'Você confirmou';
            };
            if (this.transactionable_type == 'transfer') {
              messages.hasDone = this.to_attendant.name + ' aceitou';
            };
            messages.mustDo = 'Aguarda autorização do coordenador';
          }
          break;

        case 'subject':
          if (this.isAccepted()) {
            messages.hasDone = '';
            messages.mustDo = 'Aguarda confirmação de ' + this.from_attendant.name;
          } else if (this.isRejected()) {
            messages.hasDone = 'Você rejeitou';
            messages.mustDo = '';
          } else if (this.isConfirmed()) {
            if (this.transactionable_type == 'exchange') {
              messages.hasDone = this.from_attendant.name + ' confirmou';
            };
            if (this.transactionable_type == 'transfer') {
              messages.hasDone = 'Você  aceitou';
            };
            messages.mustDo = 'Aguarda autorização do coordenador';
          }
          break;

        case 'manager':
          if (this.canAuthorize()) {
            if (this.transactionable_type == 'exchange') {
              messages.hasDone = this.from_attendant.name + ' confirmou';
            } else {
              messages.hasDone = this.to_attendant.name + ' aceitou';
            }
            messages.mustDo = 'Aguarda sua autorização';
          }
          break;

      }

      if (this.isCanceled()) {
        messages.hasDone = 'Cancelada';
        messages.mustDo = '';
      } else if (this.isDenied()) {
        messages.hasDone = 'Negada pelo coordenador';
        messages.mustDo = '';
      } else if (this.isAuthorized()) {
        messages.hasDone = 'Autorizada pelo coordenador';
        messages.mustDo = '';
      }

      return messages;
    };

    TransactionModel.prototype.viewingAs = function () {
      var userId = MdsqreAuth.getUser().id;
      if (this.from_attendant_id == userId) return 'originator';
      if (this.to_attendant_id == userId) return 'subject';
      if (this.canAuthorize()) return 'manager';
      return false;
    };

    TransactionModel.prototype.isMine = function () {
      var userId = MdsqreAuth.getUser().id;
      if (this.to_attendant_id == userId || this.from_attendant_id == userId) return true;
      return false;
    };

    TransactionModel.prototype.isAccepted = function () {
      if (this.state === 'accepted') return true;
      return false;
    };

    TransactionModel.prototype.isRejected = function () {
      if (this.state === 'rejected') return true;
      return false;
    };

    TransactionModel.prototype.isConfirmed = function () {
      if (this.state === 'confirmed') return true;
      return false;
    };

    TransactionModel.prototype.isCanceled = function () {
      if (this.state === 'canceled') return true;
      return false;
    };

    TransactionModel.prototype.isAuthorized = function () {
      if (this.state === 'authorized') return true;
      return false;
    };

    TransactionModel.prototype.isDenied = function () {
      if (this.state === 'denied') return true;
      return false;
    };

    return TransactionModel;
  }
})();

// should deprecate
(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiTransactions', MdsqreApiTransactions);

  /* @ngInject */
  function MdsqreApiTransactions(MdsqreApi, MdsqreRemapper, $q) {
    var service = {
      create: create,
      confirm: confirm,
      cancel: cancel,
      authorize: authorize,
      deny: deny
    };
    return service;

    ////////////////

    function create(transaction) {
      //
    }

    function confirm(transaction) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/offers/'+transaction.offer_id+'/transactions/'+transaction.id+'/confirm')
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function cancel(transaction) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/offers/'+transaction.offer_id+'/transactions/'+transaction.id+'/cancel')
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function authorize(transaction) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/offers/'+transaction.offer_id+'/transactions/'+transaction.id+'/authorize')
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function deny(transaction) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/offers/'+transaction.offer_id+'/transactions/'+transaction.id+'/deny')
        .then(function(transactionResponse) {
          deferred.resolve(transactionResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('UnitModel', UnitModel);

  /* @ngInject */
  function UnitModel(Model, MdsqreAuth) {

    ////////////////

    /**
     * @constructor UnitModel
     * @param {object}
     */
    function UnitModel(data) {
      Model.call(this, data);
      this._class = 'UnitModel';
      this.name = data.name;
      this.type = data.type;
      this.role = data.role;
      this.child_units = data.child_units;
      this.address = data.address;
      this.users = data.users;
      this.additional_info = data.additional_info;
    }

    UnitModel.prototype = Object.create(Model.prototype);

    return UnitModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('UserModel', UserModel);

  /* @ngInject */
  function UserModel(Model) {

    ////////////////

    /**
     * @constructor UserModel
     * @param {object}
     */
    function UserModel(data) {
      Model.call(this, data);
      this._class = 'UserModel';
      this.name = data.name;
      this.email = data.email;
      this.taxvat = data.taxvat;
      this.address = data.address;
      this.contact = data.contact;
      this.activated_at = data.activated_at;
      this.session = data.session;
      this.helpers = data.helpers;
      this.organizations = data.organizations;
      this.roles = data.roles;
      this.specialties = data.specialties;
      this.permissions = data.permissions;
      this.avatar_url = data.avatar_url;
      this.pivot = data.pivot;
      this.additional_info = data.additional_info;
    }

    UserModel.prototype = Object.create(Model.prototype);

    UserModel.prototype.hasRole = function(role) {
      if (!this.roles || !this.roles.length > 0) return false;
      var roles = [];
      this.roles.forEach(function(_role) {
        var rolePieces = _role.name.split('_');
        roles.push(rolePieces[0]);
      });
      if (roles.indexOf(role) > -1) return true;
      return false;
    };

    UserModel.prototype.getRole = function(roleToFind) {
      if (!this.roles || !this.roles.length > 0) return null;
      var role;
      this.roles.forEach(function(_role) {
        var splitRole = _role.name.split('_');
        if (splitRole[0] == roleToFind) {
          role = _role;
        }
      });
      return role;
    };

    UserModel.prototype.getSession = function(format) {
      if (this.session) {
        var session = this.session;
        if (format) {
          this.session.login_time = moment(session.login_time);
          this.session.logout_time = moment(session.last_ping);
          this.session.last_ping = moment(session.last_ping);
        }
        return this.session;
      }
    };

    UserModel.prototype.getAvatarUrl = function() {
      var img = 'http://cdn.medsquire.com/img/avatar-placeholder.png';
      if (this.additional_info && this.additional_info.crm && this.additional_info.crm_state) {
        img = 'https://portal.cfm.org.br/index.php?option=com_medicos&imagemMedico=true&crm=' + this.additional_info.crm;
        img += '&uf=' + this.additional_info.crm_state;
      }
      this.avatar_url = img;
      return this.avatar_url;
    };

    UserModel.prototype.isActivated = function() {
      if (this.activated_at) return true;
      return false;
    };

    return UserModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiUsers', MdsqreApiUsers);

  /* @ngInject */
  function MdsqreApiUsers(MdsqreApi, $q, UserModel, RatingModel, ErrorModel) {
    var service = {
      list: list,
      create: create,
      view: view,
      update: update,
      remove: remove,

      settings: settings,

      rating: rating,
    };
    return service;

    ////////////////

    function list(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/users', params: params })
        .then(function(usersResponse) {
          var users = [];
          usersResponse.data.data.forEach(function(user) {
            users.push(new UserModel(user));
          });
          usersResponse.data.data = users;
          deferred.resolve(usersResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function create(user) {
      var deferred = $q.defer();
      var data = {
        name: user.name,
        email: user.email,
        password: user.password,
        roles: user.roles,
        units: user.units,
      };
      MdsqreApi.request('POST', 'api/v1/users', data)
        .then(function(userResponse) {
          userResponse.data.data = new UserModel(userResponse.data.data);
          deferred.resolve(userResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(userId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/users/' + userId)
        .then(function(userResponse) {
          userResponse.data.data = new UserModel(userResponse.data.data);
          deferred.resolve(userResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function update(user) {
      var deferred = $q.defer();
      MdsqreApi.request('PUT', 'api/v1/users/' + user.id, user)
        .then(function(userResponse) {
          userResponse.data.data = new UserModel(userResponse.data.data);
          deferred.resolve(userResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(userId) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/users/' + userId)
        .then(function(deleteResponse) {
          deferred.resolve(deleteResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function settings(userId, params) {
      var deferred = $q.defer();
      var data = { type: rating.type, rate: rating.rate, additional_info: rating.additional_info };
      MdsqreApi.request('GET', { url: 'api/v1/users/' + userId + '/settings', params: params })
        .then(function(settingsResponse) {
          deferred.resolve(settingsResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function rating(rating) {
      var deferred = $q.defer();
      var data = { type: rating.type, rate: rating.rate, additional_info: rating.additional_info };
      MdsqreApi.request('POST', 'api/v1/users/' + rating.user_id + '/rating', data)
        .then(function(ratingResponse) {
          ratingResponse.data.data = new RatingModel(ratingResponse.data.data);
          deferred.resolve(ratingResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre')
    .config(uiRouterStateDecorator);

  function uiRouterStateDecorator($provide, APP_CONTEXTS) {
    $provide.decorator('$state', function($delegate) {

      // let's locally use 'state' name
      var state = $delegate;

      // let's extend this object with new function
      // 'baseGo', which in fact, will keep the reference
      // to the original 'go' function
      state.baseGo = state.go;

      // here comes our new 'go' decoration
      var go = function(to, params, options) {
        options = options || {};
        params = params || {};

        console.warn('medsquire decorator on $state.go()');
        if (window._Medsquire && window._Medsquire.context) {
          var shortContext = APP_CONTEXTS.short[window._Medsquire.context];
          var routePieces = to.split('.');
          if (routePieces[0] === '*') {
            console.warn('medsquire context placeholder found: rewriting state');
            routePieces[0] = shortContext;
            to = routePieces.join('.');
            console.warn('medsquire context rewrited: ' + to);
          }
        }

        console.log(to);
        console.log(params);
        console.log(options);

        // return processing to the 'baseGo' - original
        if (window._Medsquire) {
          window._Medsquire.stateTimestamp = Date.now();
        }
        this.baseGo(to, params, options);
      };

      // assign new 'go', right now decorating the old 'go'
      state.go = go;

      return $delegate;
    });
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('BaseRequester', BaseRequester);

  /* @ngInject */
  function BaseRequester($http, $q, MdsqreApi, MdsqreRemapper, MDSQRE_API_ENDPOINT,
    $cookies, $localStorage, $log, ErrorModel) {

    ////////////////

    /**
     * @constructor BaseRequester
     * @param {object}
     */
    function BaseRequester() {
      this._class = 'BaseRequester';
      this.env = 'sandbox';
      this.routes = [];
      this.query = [];
      this.baseUrl = null;
      this.url = null;
      this.request = null;
      this.remapper = null;
      this.mock = false;

      this.init();
    }

    BaseRequester.prototype.init = function() {
      this.env = MdsqreApi.env;
      this.routes = [];
      this.query = [];
      this.baseUrl = null;
      this.url = null;
      this.request = null;
      this.remapper = null;
      this.mock = false;
    };

    BaseRequester.prototype.get = function(params) {
      return this.runRequest('GET', this.getUrl(), [], params);
    };

    BaseRequester.prototype.post = function(data) {
      return this.runRequest('POST', this.getUrl(), data);
    };

    BaseRequester.prototype.put = function(data) {
      return this.runRequest('PUT', this.getUrl(), data);
    };

    BaseRequester.prototype.delete = function(data) {
      return this.runRequest('DELETE', this.getUrl(), data);
    };

    BaseRequester.prototype.list = function(params) {
      var self = this;
      var deferred = $q.defer();
      this.get(params).then(function(response) {
        if (self.remapper) response = self.remapperResponse(response, true);
        deferred.resolve(response.data);
      }).catch(function(error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    BaseRequester.prototype.view = function(params) {
      var self = this;
      var deferred = $q.defer();
      this.get(params).then(function(response) {
        if (self.remapper) response = self.remapperResponse(response);
        deferred.resolve(response.data);
      }).catch(function(error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    BaseRequester.prototype.save = function(data) {
      return this.post(data);
    };

    BaseRequester.prototype.update = function(data) {
      return this.put(data);
    };

    BaseRequester.prototype.login = function(credentials) {
      var self = this;
      var deferred = $q.defer();
      var login = this.runRequest('POST', this.getUrl(), credentials)
        .then(function(loginResponse) {
          $cookies.put('session_id', loginResponse.data.session_id);
          $cookies.put('auth_token', loginResponse.data.access_token);
          return loginResponse;
        })
        .then(function(loginResponse) {
          MdsqreApi.getMe()
            .then(function(userResponse) {
              $localStorage.user = MdsqreRemapper.user(userResponse.data);
              deferred.resolve(loginResponse.data);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        })
        .catch(function(error) {
          deferred.reject(error);
        });
      return deferred.promise;
    };

    BaseRequester.prototype.buildRequest = function(method, url, data, params) {
      var request = {};
      request.method = method;
      request.url = url;
      if (data) request['data'] = JSON.stringify(data);
      if (params) {
        var query = '';
        for (var param in params) {
          if (params[param] instanceof Array) {
            params[param].forEach(function(_param) {
              query += param + '[]=' + _param + '&';
            });
          } else {
            query += param + '=' + params[param] + '&';
          }
        }
        request.url += ('?' + query);
      }
      this.request = request;
      return this;
    };

    BaseRequester.prototype.runRequest = function(method, url, data, params) {
      var request = this.buildRequest(method, url, data, params);
      var deferred = $q.defer();
      $http(this.request)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          $log.error(error);
          var beautyError = MdsqreRemapper.error(error.data)
          deferred.reject(beautyError);
        });
      return deferred.promise;
    };

    BaseRequester.prototype.remapperResponse = function(response, multiple) {
      if (!this.remapper) throw new Error('Remapper was not defined');
      if (multiple) {
        var self = this;
        var list = [];
        response.data.data.forEach(function(item) {
          list.push(self.remapper(item));
        });
        response.data.data = list;
        self.remapper = null;
        return response;
      }
      response.data.data = this.remapper(response.data.data);
      this.remapper = null;
      return response;
    };

    BaseRequester.prototype.getUrl = function() {
      var routes = this.routes;
      if (!routes || routes.length <= 0) {
        throw new Error("BaseRequester invalid routes");
      }
      var url = routes.join('/');
      if (this.baseUrl) {
        url = this.baseUrl + '/' + url;
      } else {
        url = MDSQRE_API_ENDPOINT[this.env] + '/' + url;
      }
      this.baseUrl = null; // clean
      this.routes = []; // clean
      this.url = url;
      return this.url;
    };

    return BaseRequester;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiNotificationsTemplates', MdsqreApiNotificationsTemplates);

  /* @ngInject */
  function MdsqreApiNotificationsTemplates(MdsqreApi, $q, ErrorModel) {
    var service = {
      create: create,
      list: list,
      view: view,
      update: update,
    };
    return service;

    ////////////////

    function create(template) {
      var deferred = $q.defer();
      var data = {
        short_title: template.short_title,
        short_content: template.short_content,
        long_title: template.long_title,
        long_content: template.long_content,
        button_title: template.button_title,
        button_url: template.button_url,
      };
      MdsqreApi.request('POST', 'api/v1/notifications/template', data)
      .then(function(templateResponse) {
        deferred.resolve(templateResponse);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function list() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/notifications/template')
      .then(function(templatesResponse) {
        var templates = [];
        templatesResponse.data.data.forEach(function(template) {
          // do something
          templates.push(template);
        });
        templatesResponse.data.data = templates;
        deferred.resolve(templatesResponse.data);
      })
      .catch(function(errorResponse) {
        console.log(errorResponse);
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function view(id) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/notifications/template/'+id)
      .then(function(templateResponse) {
        deferred.resolve(templateResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function update(template) {
      var deferred = $q.defer();
      var data = {
        short_title: template.short_title,
        short_content: template.short_content,
        long_title: template.long_title,
        long_content: template.long_content,
        button_title: template.button_title,
        button_url: template.button_url,
      };
      MdsqreApi.request('PUT', 'api/v1/notifications/template/'+template.id, data)
      .then(function(templateResponse) {
        deferred.resolve(templateResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiHospitalUnits', MdsqreApiHospitalUnits);

  /* @ngInject */
  function MdsqreApiHospitalUnits(MdsqreApi, $q, MdsqreRemapper) {
    var service = {
      create: create,
      list: list,
      view: view,
      update: update,
      remove: remove,
    };
    return service;

    ////////////////

    function create(unit) {
      var deferred = $q.defer();
      var unit = {
        name: unit.name,
        type: unit.type,
        address: {
          street: unit.address.street,
          number: unit.address.number,
          district: unit.address.district,
          city: unit.address.city,
          zip: unit.address.zip,
          state: unit.address.state,
          country: unit.address.country,
          gplace_id: unit.address.gplace_id,
        }
      };
      MdsqreApi.request('POST', 'api/v1/hospitals', unit)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function list(filters) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/hospitals')
        .then(function(unitsResponse) {
          var units = [];
          unitsResponse.data.data.forEach(function(unit) {
            units.push(MdsqreRemapper.unit(unit));
          });
          unitsResponse.data.data = units;
          deferred.resolve(unitsResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(id) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/hospitals/'+id)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function update(unit) {
      var deferred = $q.defer();
      var data = {
        name: unit.name,
        type: unit.type,
        address: {
          street: unit.address.street,
          number: unit.address.number,
          district: unit.address.district,
          city: unit.address.city,
          zip: unit.address.zip,
          state: unit.address.state,
          country: unit.address.country,
          gplace_id: unit.address.gplace_id,
        }
      };
      MdsqreApi.request('PUT', 'api/v1/hospitals/'+unit.id, data)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(id) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/hospitals/'+id)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiUsersHelpers', MdsqreApiUsersHelpers);

  /* @ngInject */
  function MdsqreApiUsersHelpers(MdsqreApi, $q, ErrorModel) {
    var service = {
      botlink: botlink,
      resendActivation: resendActivation,
    };
    return service;

    ////////////////

    function botlink(userId) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/users/'+userId+'/helpers/botlink')
        .then(function(request) {
          deferred.resolve(request.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function resendActivation(userId) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/users/'+userId+'/helpers/activation/resend')
        .then(function(request) {
          deferred.resolve(request.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('NoticeModel', NoticeModel);

  /* @ngInject */
  function NoticeModel(Model) {

    ////////////////

    /**
     * @constructor NoticeModel
     * @param {object}
     */
    function NoticeModel(data) {
      Model.call(this, data);
      this._class = 'NoticeModel';
      this.type = data.type;
      this.data = data.data;
      this.read_at = data.read_at;
      this.created_at = data.created_at;
    }

    NoticeModel.prototype = Object.create(Model.prototype);

    return NoticeModel;
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiMe', MdsqreApiMe);

  /* @ngInject */
  function MdsqreApiMe(MdsqreApi, MdsqreRemapper, $q) {
    var service = {
      events: events,
      eventOffers: eventOffers,
      eventsSummary: eventsSummary,
      invoicesSummary: invoicesSummary,
      notices: notices,
      permissions: permissions,
    };
    return service;

    ////////////////

    function events(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/user/events', params: params })
      .then(function(eventsResponse) {
        var events = [];
        eventsResponse.data.data.forEach(function(event) {
          events.push(MdsqreRemapper.event(event));
        });
        eventsResponse.data.data = events;
        deferred.resolve(eventsResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function eventOffers(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/user/events/offers', params: params })
        .then(function(offersResponse) {
          var offers = [];
          offersResponse.data.data.forEach(function(offer) {
            // offers.push(new ShiftModel(offer));
            offers.push(MdsqreRemapper.offer(offer));
          });
          offersResponse.data.data = offers;
          deferred.resolve(offersResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(new ErrorModel(errorResponse.data));
        });
      return deferred.promise;
    }

    function eventsSummary(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/user/summary/events', params: params })
      .then(function(summaryResponse) {
        deferred.resolve(summaryResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function invoicesSummary(params) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', { url: 'api/v1/user/summary/invoices', params: params })
      .then(function(summaryResponse) {
        deferred.resolve(summaryResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function notices() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/user/notifications')
      .then(function(notificationsResponse) {
        var notices = [];
        notificationsResponse.data.data.forEach(function(notice) {
          notices.push(MdsqreRemapper.notification(notice));
        });
        notificationsResponse.data.data = notices;
        deferred.resolve(notificationsResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }

    function permissions() {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/user/permissions')
      .then(function(permissionsResponse) {
        deferred.resolve(permissionsResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(MdsqreRemapper.error(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiUserSettings', MdsqreApiUserSettings);

  /* @ngInject */
  function MdsqreApiUserSettings(MdsqreApi, $q, ErrorModel) {
    var service = {
      create: create,
      list: list,
      view: view,
      update: update,
      toggle: toggle,
      remove: remove,
    };
    return service;

    ////////////////

    function create(userId, setting) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/users/'+userId+'/settings', setting)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function list(userId, params) {
      if (!params) params = {};
      params.plucked = true;
      var deferred = $q.defer();
      MdsqreApi.request('GET', {url: 'api/v1/users/'+userId+'/settings', params: params})
      .then(function(settingsResponse) {
        // var settings = [];
        // settingsResponse.data.data.forEach(function(setting) {
        //   // do something
        //   settings.push(setting);
        // });
        // settingsResponse.data.data = settings;
        deferred.resolve(settingsResponse.data);
      })
      .catch(function(errorResponse) {
        console.log(errorResponse);
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function view(userId, settingId) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/users/'+userId+'/settings/'+settingId)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function update(userId, settingId, setting) {
      var deferred = $q.defer();
      MdsqreApi.request('PUT', 'api/v1/users/'+userId+'/settings/'+settingId, setting)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function toggle(userId, data) {
      var deferred = $q.defer();
      MdsqreApi.request('POST', 'api/v1/users/'+userId+'/settings/toggle', data)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }

    function remove(userId, settingId) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/users/'+userId+'/settings/'+settingId)
      .then(function(settingResponse) {
        deferred.resolve(settingResponse.data);
      })
      .catch(function(errorResponse) {
        deferred.reject(new ErrorModel(errorResponse.data));
      });
      return deferred.promise;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('ng-mdsqre.api')
    .factory('MdsqreApiUnitsHospitalUsers', MdsqreApiUnitsHospitalUsers);

  /* @ngInject */
  function MdsqreApiUnitsHospitalUsers(MdsqreApi, $q, MdsqreRemapper) {
    var service = {
      // create: create,
      list: list,
      // view: view,
      // update: update,
      // remove: remove,
    };
    return service;

    ////////////////

    function create(unit) {
      var deferred = $q.defer();
      var unit = {
        name: unit.name,
        type: unit.type,
        address: {
          street: unit.address.street,
          number: unit.address.number,
          district: unit.address.district,
          city: unit.address.city,
          zip: unit.address.zip,
          state: unit.address.state,
          country: unit.address.country,
          gplace_id: unit.address.gplace_id,
        }
      };
      MdsqreApi.request('POST', 'api/v1/hospitals', unit)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function list(unitId, filters) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/hospitals/'+unitId+'/users')
        .then(function(usersResponse) {
          var users = [];
          usersResponse.data.data.forEach(function(user) {
            users.push(MdsqreRemapper.user(user));
          });
          usersResponse.data.data = users;
          deferred.resolve(usersResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function view(id) {
      var deferred = $q.defer();
      MdsqreApi.request('GET', 'api/v1/hospitals/'+id)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function update(unit) {
      var deferred = $q.defer();
      var data = {
        name: unit.name,
        type: unit.type,
        address: {
          street: unit.address.street,
          number: unit.address.number,
          district: unit.address.district,
          city: unit.address.city,
          zip: unit.address.zip,
          state: unit.address.state,
          country: unit.address.country,
          gplace_id: unit.address.gplace_id,
        }
      };
      MdsqreApi.request('PUT', 'api/v1/hospitals/'+unit.id, data)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }

    function remove(id) {
      var deferred = $q.defer();
      MdsqreApi.request('DELETE', 'api/v1/hospitals/'+id)
        .then(function(unitResponse) {
          unitResponse.data.data = MdsqreRemapper.unit(unitResponse.data.data);
          deferred.resolve(unitResponse.data);
        })
        .catch(function(errorResponse) {
          deferred.reject(MdsqreRemapper.error(errorResponse.data));
        });
      return deferred.promise;
    }
  }
})();
